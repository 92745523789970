import { SingleBrandPage ,ProductPage,Cart,VerifyNumber ,SignUp ,Marketplace, Login,Checkout ,
        Order,BundleDescriptionScreen , OrderDetail ,Account , FleekTab, BundleScreen, OrderConfirmation} from "../Pages";

import { Routes, Route ,Outlet, Navigate} from "react-router-dom";

export const WebSiteRoutes = ({isLoggedIn}) => {
  const access_token = localStorage.getItem('accesstoken')
    return (
      <Routes>
        <Route path="brand" element={<Outlet/>} >
            <Route path=":vendor_name" element={<SingleBrandPage isLoggedIn={isLoggedIn} />} />
        </Route>
        <Route path="bundle" element={<Outlet/>} >
            <Route path=":bundle_id" element={<BundleDescriptionScreen isLoggedIn={isLoggedIn} />} />
            <Route path="" element={<BundleScreen isLoggedIn={isLoggedIn} />} />
        </Route>
        <Route path="product" element={<Outlet/>} >
            <Route path=":product_id" element={<ProductPage/>} />
        </Route>
        <Route path="cart" element={<Cart/>}>
        </Route>
        <Route path="checkout" element={<Checkout/>} />
        <Route path="coupon" element={<Checkout coupon={true}/>} />

        {
          !access_token &&
          <Route path="login" element={<Outlet/>} >
            <Route path="verify-user" element={<VerifyNumber />} />
            <Route path="" element={<Login />}/>
         </Route> 
        }
        <Route path="order" element = {<Order/>}/>
        <Route path="order-confirmation" element = {<OrderConfirmation/>}/>
        <Route path="signup" element={<SignUp />} />
        <Route path="/" element={<Marketplace isLoggedIn={isLoggedIn} />} />
        <Route path="marketplace" element={<Marketplace isLoggedIn={isLoggedIn}/>} />
        <Route path="orderdetail" element={<Outlet/>} >
            <Route path=":order_id" element={<OrderDetail />} />
        </Route>
        <Route path="account" element={<Account type={'Account'} />} />
        <Route path="myorder" element={<Account type={'Myorder'} />} />
        <Route path="support" element={<Account type={'Support'} />} />

        <Route path="fleektab" element={<FleekTab/>}/>
        <Route
          path="*"
          element={<Navigate to="/marketplace" />}
        /> 
        
      </Routes>
    );
  };