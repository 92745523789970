import styles from "./YoutubeLinkCard.module.css"
import ReactPlayer from "react-player";


export const YoutubeLinkCard = ({youtube_url})=>{
    return(
        <div className={styles.card}>
            <p className={styles.title}>Go ahead and watch steps to redeem</p>
            <div className={styles.videoPlayerContainer}>
                <ReactPlayer
                    url={youtube_url}
                    config={{
                        youtube: {
                        playerVars: { showinfo: 1 },
                        },
                    }}
                    controls
                    width="100%"
                    height="11.375rem"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></ReactPlayer>
            </div>
        </div>
    )
}