import styles from "./FreeProductPurchaseCard.module.css"

export const FreeProductPurchaseCard = ()=>{
    return(
        <div className={styles.card}>
            <div className={styles.brandImgContainer}>
                <img src="https://images.unsplash.com/photo-1575881875475-31023242e3f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8M3x8fGVufDB8fHx8&w=1000&q=80" alt="Brand Img"/>
            </div>
            <div className={styles.detailContainer}>
                <p className={styles.brandName}>EASY DINER</p>
                <p className={styles.brandValidity}>Prime membership, 1 month</p>
                <p className={styles.freeText}>FREE</p>
            </div>
        </div>
    )
}