import styles from "./SignOutConfirmationPopUp.module.css";
import { Link } from "react-router-dom";
import smartlook from 'smartlook-client'

import React  from "react"
import axios from 'axios';


export const SignOutConfirmationPopUp = ({toggleModal})=>{
    const handleSignOut = ()=>{
        axios.defaults.headers.common.Authorization = undefined;
        localStorage.removeItem('accesstoken');
        localStorage.removeItem('phoneNo');
        if(process.env.REACT_APP_ENV != 'production') {
            smartlook.anonymize();
          }
    }
    return(
        <div className={styles.overlay}>
            <div className={styles.popUp}>
                <p className={styles.text}>Are you sure you want to sign out?</p>
                <div className={styles.buttonContainer}>
                    <button onClick={toggleModal} className={styles.cancelButton}>CANCEL</button>
                    <Link to={"/login"} onClick={handleSignOut} className={styles.logOutButton}>SIGN OUT</Link>
                </div>
            </div>
        </div>    
    )
}