import styles from "./SignUp.module.css";

import { useState } from "react";
import chevronLeft from "../../asset/icons/chevronLeft.png"
import crossIcon from "../../asset/icons/crossCircle.png"
import { LoginSignupCommon } from "../../commonComponents";
import  Api from "../../utils/Api/Api";
import { useNavigate } from "react-router-dom";

import cx from 'classnames';

export const SignUp = ()=>{
    const navigate = useNavigate()
    const [formValue,setFormValue] = useState({
        name:"",
        phone: localStorage.getItem('phoneNo') || '' 
    });
    const [errorText,setErrorText] = useState("");
    const handleChange = (e)=>{
        const name = e.target.name;
        const value = e.target.value;
        if(name==="phone" && value.length>10){
            return;
        }
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if(format.test(value)){
            setErrorText("Name cannot contain special characters")
            setFormValue((pre_state)=>{
                return {...pre_state,[name]:value}
            })
            return;
        }
        if(value.length>30){
            setErrorText("You’re exceeding the character limit (30)")
            setFormValue((pre_state)=>{
                return {...pre_state,[name]:value}
            })
            return;
        }
        setErrorText("");
        setFormValue((pre_state)=>{
            return {...pre_state,[name]:value}
        })
        
    }
    const eraseInput = (e)=>{
        const name = e.target.name;
        setFormValue((pre_state)=>{
            return {...pre_state,[name]:""}
        })
        setErrorText("");
    }

    const isActive = ()=>{
        if(errorText){
            return false;
        }
        if(formValue.name.length>0 && formValue.phone.length===10){
            return true
        } 
        return false
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        await Api.SignUp(formValue);
        navigate(`/login/verify-user`)
    }
    return(
        <div className={styles.content}>
            <div className={styles.elements}>
                <div className={styles.upperContent}>
                    <img onClick={() => navigate(-1)} className={styles.chevron} src={chevronLeft} alt="chevronLeft" />
                    <p className={styles.uppertitle}>Create your account</p>
                    <p className={styles.uppersubTitle}>Discover. Purchase. Earn Rewards. </p>
                </div>
                <LoginSignupCommon title={"Create your account"} subtitile={"Discover. Purchase. Earn Rewards."} />
                <form id="signupForm" onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formGroup}>
                        <label className={styles.title}>Name</label>
                        <div className={ errorText ? cx(styles.inputWrapper,styles.error) : styles.inputWrapper }>
                            <input 
                                onWheelCapture={e => {
                                    e.target.blur()
                                }}
                                onChange={handleChange}
                                type="text"
                                name="name"
                                placeholder="Enter full name"
                                value={formValue.name}
                                maxLength={30}
                                className={styles.input}/>
                                
                            <img onClick={eraseInput} name="name" className={formValue.name.length>0 ?cx(styles.crossSign,styles.display): styles.crossSign} src={crossIcon} alt="crossIcon" />
                        </div>
                        {errorText.length>0 && <p className={styles.errorText}>{errorText}</p>}
                        <div>
                        </div>
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.title}>Phone number</label>
                        <div className={styles.inputWrapper}>
                            <p className={styles.countryCode}>+91</p>
                            <input 
                                onWheelCapture={e => {
                                    e.target.blur()
                                }}
                                onChange={handleChange}
                                disabled
                                type="number"
                                name="phone"
                                placeholder="Enter phone number"
                                value={formValue.phone}
                                className={styles.input}/>
                                
                            {/* <img onClick={eraseInput} name="phone" className={formValue.phone.length>0 ?cx(styles.crossSign,styles.display): styles.crossSign} src={crossIcon} alt="crossIcon" /> */}
                        </div>
                    </div>
                    
                </form>
                <div className={styles.bottomOption}>
                    <button disabled={!isActive()} form="signupForm" className={isActive()? cx(styles.Button,styles.active):styles.Button} type="submit">CREATE ACCOUNT</button>
                    <div className={styles.belowText}>
                        <p>
                        By creating Fleek account, you are agreeing to our
                        </p>
                        <a href="https://www.getfleek.app/terms-and-conditions" rel="noreferrer" target="_blank" className={styles.pointerText}>Terms & Conditions</a>
                    </div>
                </div>
            </div>
        </div>
    )
}