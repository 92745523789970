import React,{ useState ,useEffect } from "react";
import styles from "./DesktopAccountDetail.module.css"
import Pencil from "../../asset/icons/DesktopPencil.png"
import cx from 'classnames';
import  Api from "../../utils/Api/Api";
import  Helper from "../../utils/Helper";

import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import crossIcon from "../../asset/icons/crossCircle.png"


export const DesktopAccountDetail = ({userDetails, setUserDetails})=>{
    const [editMode,setEditMode] = useState(false)
    const [loading,setLoading] = useState(false);
    const [hasError,setError] = useState(false);
    

    const toggleEditMode = ()=>{
        setEditMode((pre_state)=>(!pre_state))
    }

    
    const clearInput = (e)=>{
        const name = e.target.name;
        setUserDetails((pre_state)=>{
            return {...pre_state,[name]:""}
        })
    }
    const handleChange = (e, img)=>{
        const name = e.target.name;
        const value = !img ? e.target.value: e.target.files[0];
        setUserDetails((pre_state)=>{
            return {...pre_state,[name]:value}
        })
    }

    const handleChipChange = (e)=>{
        const name = e.target.getAttribute('name')
        const value = e.target.getAttribute('value')
        setUserDetails((pre_state)=>{
            return {...pre_state,[name]:value}
        })
    }

    const SaveChanges = async () => {
        try {
            const payload = {
                    name: userDetails.name,
                    email: userDetails.email,
                    dob: userDetails.dob ? userDetails.dob : undefined,
                    gender: userDetails.gender ? userDetails.gender : undefined,
                    image_file: userDetails.image_file ? userDetails.image_file : undefined
            }

            await Api.UpdateProile(payload);
            toggleEditMode()
        }
        catch(err) {
            console.log('=======err=========', err)
        }
    }

    if(hasError) {
        return <ErrorFallback />
    }
    if(loading) {
        return <Loading />
    }
    const {name,email, avatar_url, dob, gender, phone_number} = userDetails;
    const date = Helper.dateFormat(dob);
    return(
        <div className={styles.container}>
            <div className={styles.nameBox}>
                <div className={styles.userImgContainer}>
                    {
                        userDetails.image_file || avatar_url ? 
                            <img key={new Date().getTime()} className={styles.userMainImg} src={
                                userDetails.image_file ? 
                                URL.createObjectURL(userDetails.image_file): avatar_url
                            } alt="CameraIcon"/>
                            :
                            <p className={styles.userImgLetter}>{name[0]}</p>
                    }
                    {/* {editMode && <input 
                        onChange = {(e) => handleChange(e,1)}
                        accept=".png, .jpg"
                        type="file" id="file-input" name="image_file" />} */}
                </div>
                <p className={styles.name}>{name}</p>
                <p className={styles.mobileNumber}>+91 {phone_number}</p>
                {!editMode && <img onClick={toggleEditMode} src={Pencil} alt="Pencil" className={styles.pencilIcon}/>}
            </div>
            {!editMode && <div className={styles.detailContainer}>
                <div className={styles.row}>
                    <p className={styles.column1}>Name</p>
                    <p className={styles.column2}>{name}</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.column1}>Phone number</p>
                    <p className={styles.column2}>{phone_number}</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.column1}>E - mail</p>
                    <p className={styles.column2}>{email || '-'}</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.column1}>Date of birth</p>
                    <p className={styles.column2}>{date || '-'}</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.column1}>Gender</p>
                    <p className={styles.column2}>{gender || '-'}</p>
                </div>
            </div>}
            { editMode && <div className={styles.editDetailContainer}>
                <p className={styles.editProfileTitle}>Edit profile</p>
                <div className={cx(styles.inputBundle,styles.firstBundle)}>
                    <p className={styles.inputTitle}>Name</p>
                    <div className={styles.inputWrapper}>
                        <input  placeholder="Enter Your Name" type="text" required name='name' onChange={handleChange} value={name} className={styles.input}/>
                        <img onClick={clearInput} name='name' className={name.length>0 ?cx(styles.crossSign,styles.display): styles.crossSign} src={crossIcon} alt="crossIcon" />
                    </div>
                </div>
                <div className={styles.inputBundle}>
                    <p className={styles.inputTitle}>Phone Number</p>
                    <div className={cx(styles.inputWrapper,styles.disable)}>
                        <p className={styles.countryCode}>+91</p>
                        <div className={cx(styles.input,styles.disable)}>
                            {phone_number}
                         </div>   
                    </div>
                </div>
                <div className={styles.inputBundle}>
                    <p className={styles.inputTitle}>E-mail</p>
                    <div className={styles.inputWrapper}>
                    <input placeholder="Enter Your Email" type="email" required name='email' onChange={handleChange} value={email} className={styles.input}/>
                        <img onClick={clearInput} name='email' className={email.length>0 ?cx(styles.crossSign,styles.display): styles.crossSign} src={crossIcon} alt="crossIcon" />
                    </div>
                </div>
                <div className={styles.inputBundle}>
                    <p className={styles.inputTitle}>Date of birth</p>
                    <div className={styles.inputWrapper}>
                    <input placeholder="Enter Your DateOfBirth" name='dob' onChange={handleChange} value={dob} type="date" className={styles.input}/>
                    </div>
                </div>
                <div className={styles.inputBundle}>
                    <p className={styles.inputTitle}>Gender</p>
                    <div className={styles.chipsContainer}>
                        <div onClick={handleChipChange} name="gender" value="Male" className={gender==="Male"? cx(styles.chip,styles.active) : styles.chip}>
                            Male
                        </div>
                        <div onClick={handleChipChange} name="gender" value="Female" className={gender==="Female"? cx(styles.chip,styles.active) : styles.chip}>
                            Female
                        </div>
                        <div onClick={handleChipChange} name="gender" value="Other" className={gender==="Other"? cx(styles.chip,styles.active) : styles.chip}>
                            Others
                        </div>
                    </div>
                </div>
                <button onClick={SaveChanges} className={styles.saveButton}>
                    SAVE CHANGES
                </button>
             </div>   
            }
        </div>
    )
}