import React,{ useState  } from "react";


import styles from "./Login.module.css";
import WhatsAppIcon from "../../asset/icons/WhatsAppIcon.png"
import MobileIcon from "../../asset/icons/MobileIcon.png"
import { NumberEntryPopUp ,BrandAgeConBottomSheet ,WhatsAppPopUp } from "../../components";
import { LoginSignupCommon } from "../../commonComponents";
import  Api from "../../utils/Api/Api";
import { useNavigate } from "react-router-dom";

export const Login = ()=>{
    const navigate = useNavigate()

    const [showPopUps,setShowPopUps] = useState({whatsApp:false,mobileNoEntry:true});

    const toggelNumberEntryPopUp = ()=>{
        setShowPopUps((pre_state)=>{
            return {...pre_state,mobileNoEntry:!pre_state.mobileNoEntry}
        })
    }

    const toggelWhatsAppPopUp = async ()=>{
        try{
            setShowPopUps((pre_state)=>{
                return {...pre_state,whatsApp:!pre_state.whatsApp}
            })
            const { data } = await Api.LoginWhatsapp();

            if(data && data.data && data.data.intent){
                let intent = data.data.intent;
                intent = intent.replace('whatsapp://', 'https://web.whatsapp.com/');
                window.location.href = intent;
            }
        }
        catch(err){
            console.log('----------', err)
        }
        
    }
    const GetOtp = async (phoneNo)=>{
        try{
            const {data} = await Api.GetOtp({phone:phoneNo});
            localStorage.setItem('phoneNo', phoneNo);
            if(!data) {
                return navigate(`/signup`);
            }
            navigate(`/login/verify-user`)
        }
        catch(err){
            console.log('----------', err)
        }
    }
    return(
        <div className={styles.LoginPage}>
            <div className={styles.mobileLogin}>
                <div className={styles.loginHome}>
                    {/* <div className={styles.backCard}>
                        <img className={styles.cheveron} src={chevronLeft} alt="Cheveron" />
                        <div className={styles.welcomeText}>Welcome to Fleek</div>
                        <div className={styles.welcomeSubText}>Enter your phone number to continue</div>
                    </div> */}
                    {/* <LoginSignupCommon title={"Welcome to Fleek"} subtitile={"Enter your phone number to continue"} /> */}
                    {/* <div className={styles.optionContainer}> 
                        <p className={styles.getStarted}>Get started with</p>
                        <div onClick={toggelWhatsAppPopUp} className={styles.actionButton}>
                            <img className={styles.whatsAppIcon} src={WhatsAppIcon} alt="MobileIcon"/>
                            <p>LOGIN WITH WHATSAPP</p>
                        </div>
                        <p className={styles.OrText}>Or</p>
                        <div onClick={toggelNumberEntryPopUp} className={styles.actionButton}>
                            <img className={styles.mobIcon} src={MobileIcon} alt="MobileIcon"/>
                            <p>LOGIN WITH PHONE NUMBER</p>
                        </div>
                    </div> */}
                    {/* <div className={styles.termContainer}>
                        <p>By creating Fleek account, you are agreeing to our</p>
                        <div className={styles.termsButton}>Terms & Conditions</div>
                    </div> */}
                </div>
                {showPopUps.mobileNoEntry && <NumberEntryPopUp GetOtp = {GetOtp} toggle={toggelNumberEntryPopUp}/>}
                {showPopUps.whatsApp &&  <WhatsAppPopUp toggle={toggelWhatsAppPopUp}/>}
            </div>    
        </div>
    )
}