import styles from "./DigitalBrandFaq.module.css"
import React , {useState} from "react"
import chevronDown from "../../asset/icons/chevronDown.png"
import chevronUp from "../../asset/icons/chevronUp.png"
import { FaqQueAns } from "../FaqQueAns/FaqQueAns"


export const DigitalBrandFaq= ({faqList})=>{
    const [showFaqs,setShowFaqs] = useState(false);
    console.log()
    return(
        <div className={styles.FaqCard}>
            <div onClick={()=>{
                setShowFaqs((pre_state)=>(!pre_state))
            }} className={styles.ActionArea}>
                <div className={styles.Title}>
                    FAQs
                </div>
                <div className={styles.checvronDiv}>
                    {showFaqs?
                        <img className={styles.chevronDownClass} src={chevronUp} alt="Chevron Up" />
                        : <img className={styles.chevronDownClass} src={chevronDown} alt="Chevron Down" />}
                </div>
            </div>
            {showFaqs && 
            <div className={styles.faqsContainer}>
                {
                    faqList.map((ele)=>(
                        <FaqQueAns QueAnsObj = {ele}/>
                    ))
                }
                {/* <FaqQueAns/>
                <FaqQueAns/>
                <FaqQueAns/>
                <FaqQueAns QueAnsObj={obj}/> */}
            </div>}
        </div>    
    )
}