import { useNavigate, useSearchParams } from "react-router-dom";
import React , {useEffect} from "react"


export const OrderConfirmation = ()=>{
    const [searchParams] = useSearchParams();
    const order_id = searchParams.get("order_id")

    const navigate = useNavigate();
    useEffect(()=>{
        navigate(`/order?order_id=${order_id}`)
    });
    
    return(
        <div>
           ... pending
        </div>
    )
}