import axios from 'axios';
import Helper from './utils/Helper';
import Smartlook from 'smartlook-client'
import { HelmetProvider } from 'react-helmet-async';

import { useLocation } from "react-router-dom";

import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/Error/Error';

import './App.css';
import { WebSiteRoutes } from './WebSiteRoutes/WebSiteRoutes';
import { NavBar } from "./commonComponents";

import { Footer } from './commonComponents';

import { MobileBottomNavBar } from './commonComponents';

if(process.env.REACT_APP_ENV === 'production') {
  Smartlook.init('16ede8dcfbea40c36dd752c9a5dec79f5674d402')
}

function App() {

localStorage.setItem('webApp', true);

let {pathname} = useLocation();
pathname = pathname.split('/')[1];

let showNavBar = !pathname ? false : ['/login', '/verify-user', '/signup', '/login/verify-user'].toString().includes(pathname);

const showBottomNavBar = ['/', '/marketplace/','/fleektab/' ,'/account/'].toString().includes(pathname)

const isMobile = Helper.isMobile()
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const isLoggedIn = localStorage.getItem('accesstoken') ? true: false;
if(isLoggedIn){
  axios.defaults.headers.common.Authorization = localStorage.getItem('accesstoken');
}
axios.interceptors.response.use((response) => response, (error) => {
  if (error && error.response && error.response.status === 401) {
    
    localStorage.removeItem('accesstoken');
    window.location.href = '/login';
  }
  return error;
});
  return (
    <HelmetProvider >
      <div className="App">
      {!isMobile && !showNavBar && <NavBar isLoggedIn = {isLoggedIn} />}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <WebSiteRoutes isLoggedIn={isLoggedIn} />
      </ErrorBoundary> 
      {isMobile &&  showBottomNavBar &&
        <>
          <div className='spacer'>
            <MobileBottomNavBar type={pathname} isLoggedIn = {isLoggedIn}/>
          </div>
        </>
      } 
      {!isMobile && !showNavBar && <Footer/>}
    </div>
    </HelmetProvider>
    
  );
}

export default App;
