import styles from "./PaymentDetailsCard.module.css"
import FleekCoin from "../../asset/icons/fleek-coin-logo.png"


export const PaymentDetailsCard = ({useFleekCoin, cartData = {}})=>{
    const {mrp_price,cost, actual_discounted_cost, coin_value_reserved, couponDiscount} = cartData;

    console.log('===================', cartData)
    return(
        <div className={styles.card}>
            <div className={styles.flexRow}>
                <p className={styles.paymentTitle}>Payment details</p>
                <p className={styles.noOfItemTitle}>1 item</p>
            </div>
            <div className={styles.itemContainer}>
                <p className={styles.itemName}>Item total</p>
                <p className={styles.price}>₹ {Math.round(mrp_price)}</p>
            </div>
            {(Math.round(mrp_price - cost)) ? <div className={styles.itemContainer}>
                <p className={styles.itemName}>Discount</p>
                <p className={styles.price}>- ₹ {Math.round(mrp_price - cost)}</p>
            </div>: '' }
            {(couponDiscount) ? <div className={styles.itemContainer}>
                <p className={styles.itemName}>Coupon code</p>
                <p className={styles.price}>- ₹ {Math.round(couponDiscount)}</p>
            </div>: '' }
            {useFleekCoin && coin_value_reserved && Number(coin_value_reserved) ? <div className={styles.flexRow}>
                <div className={styles.fleekCoinText}>
                    <p>FleekCoin&nbsp;</p>
                    <span><img className={styles.fleekCoin} src={FleekCoin} alt="FleekCoin" /></span>
                </div>
                <p className={styles.fleekPrice}>- ₹ {Math.round(coin_value_reserved)}</p>
            </div> : ''}
            <div className={styles.horizontalBar}>
            </div>
            <div className={styles.flexRow}>
                <p className={styles.totalText}>Total</p>
                <p className={styles.totalPrice}>₹ {useFleekCoin? Math.round(actual_discounted_cost): Math.floor(cost)}</p>
            </div>
        </div>
    )
}