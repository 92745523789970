import styles from "./Account.module.css";
import HumanIcon from "../../asset/icons/Human.png"
import Pencil from "../../asset/icons/Pencil.png"
import SaveAddresss from "../../asset/icons/SaveAddress.png"
import FleekCoin from "../../asset/icons/FleekCoin1.png"
import QuestionMark from "../../asset/icons/QuestionRoundIcon.png"
import PowerOff from "../../asset/icons/powerOff.png"
import Bag from "../../asset/icons/bag.png"
import { MobileBottomNavBar } from "../../commonComponents";
import React,{ useState ,useEffect }  from "react";
import { Link } from "react-router-dom";
import  Api from "../../utils/Api/Api";
import { NavBar } from "../../commonComponents";
import { InstallFleekComp,ProfileMobComp ,SupportComp ,MyOrder , DesktopAccountDetail
   , AccountSideButton} from "../../components";

import { SignOutConfirmationPopUp } from "../../components";

import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";

export const Account = ({type})=>{
    const isMobile = window.innerWidth <= 500;
    const [isLogin,setIsLogin] = useState(true)
    const [mobileEdit,setMobileEdit] = useState(false)
    const [hasImage,setHasImage] = useState(false)
    const [loading,setLoading] = useState(true);
    const [hasError,setError] = useState(false);
    const [showPopUp,setShowPopUp] = useState(false);
    const toggleModal = ()=>{
        setShowPopUp((pre_state)=>(!pre_state))
    }
    const [userDetails,setUserDetails] = useState({
        name:'',
        email:'',
        phone:'',
        dob:'',
        gender:'',
        img:'',
    })
    useEffect(()=>{
        const fetchUserDetails = async ()=>{
            try {
                let { data: {name ,email , avatar_url , dob , gender, phone_number} = {} } = await Api.GetUserDetails();
     
                setUserDetails({
                    name: name || '',
                    email: email || '',
                    phone_number: phone_number || '',
                    dob: dob || '',
                    gender: gender || '',
                    avatar_url: avatar_url || ''
                })
            }
            catch(err) {
                setError(true);
            }
            setLoading(false)
        }
        fetchUserDetails();
    },[])
    const hideNavOnMobile = ()=>{
        if(type ==="Account"){
            if(mobileEdit){
                return true;
            }
            return false
        }
        else{
            return true;
        }
    }

    if(hasError) {
        return <ErrorFallback />
    }
    if(loading) {
        return <Loading />
    }

    if(isMobile){
        return(
            <>
                <NavBar hideNavOnMobile = {hideNavOnMobile()}/>
                <div className={styles.content}>
                    <>
                        {
                            type==="Account" && !mobileEdit &&
                            <>{
                                isLogin ?
                                    <div onClick={()=>setMobileEdit(true)} className={styles.userOption}>
                                        <Link  style={{ textDecoration: 'none' }} to='/account' className={styles.userImgContainer}>
                                            {
                                                userDetails.avatar_url ? 
                                                    <img className={styles.userMainImg} src={userDetails.avatar_url} />
                                                    :
                                                    <p className={styles.userImgLetter}>{userDetails.name[0]}</p>
                                            }
                                            <img className={styles.Pencil} src={Pencil} alt="Pencil"/>
                                        </Link>
                                        <div className={styles.userDetail}>
                                            <p className={styles.userName}>{userDetails.name}</p>
                                            <p className={styles.userPhone}>{userDetails.phone_number}</p>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.userOption}>
                                        <div className={styles.userImgContainer}>
                                            <img src={HumanIcon} alt="HumanIcon" className={styles.userImg}/>
                                        </div>
                                        <button className={styles.loginSingupButton}>
                                            LOGIN /JOIN
                                        </button>
                                    </div>
                                    
                            }
                            {isLogin && 
                                <div className={styles.loginUserOptions}>
                                    <Link style={{ textDecoration: 'none' }} to='/myorder' className={styles.loginUserOption}>
                                        <img className={styles.icon} src={Bag} alt="Bag"/>
                                        <div className={styles.optionText}>
                                            <p className={styles.optionText1}>My orders</p>
                                            <p className={styles.optionText2}>Track & view your recent orders</p>
                                        </div>   
                                    </Link>
                                    <div className={styles.seperator}>
                                    </div>  
                                    <div className={styles.loginUserOption}>
                                        <img className={styles.icon} src={FleekCoin} alt="FleekCoin"/>
                                        <div className={styles.optionText}>
                                            <p className={styles.optionText1}>FleekCoin & rewards</p>
                                            <p className={styles.optionText2}>Earn rewards, redeem vouchers & more</p>
                                            <p className={styles.text3}>COMING SOON</p>
                                        </div>   
                                    </div>
                                    <div className={styles.seperator}>
                                    </div>  
                                    <div className={styles.loginUserOption}>
                                        <img className={styles.icon} src={SaveAddresss} alt="SaveAddresss"/>
                                        <div className={styles.optionText}>
                                            <p className={styles.optionText1}>Saved address</p>
                                            <p className={styles.optionText2}>Edit or add new address</p>
                                            <p className={styles.text3}>COMING SOON</p>
                                        </div>   
                                    </div>
                                    <div className={styles.seperator}>
                                    </div>  
                                    <Link style={{ textDecoration: 'none' }} to='/support' className={styles.loginUserOption}>
                                        <img className={styles.icon} src={QuestionMark} alt="QuestionMark"/>
                                        <div className={styles.optionText}>
                                            <p className={styles.optionText1}>Support</p>
                                            <p className={styles.optionText2}>Contact us</p>
                                        </div>   
                                    </Link> 
                                </div>
                            }
                            <InstallFleekComp/>
                            <div className={styles.otherLinkContainer}>
                                <a className={styles.linkMview} href="https://www.getfleek.app/about-us" rel="noreferrer" target="_blank">About Us</a>
                                <div className={styles.seperator1}></div>
                                <a className={styles.linkMview} href="https://www.getfleek.app/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a>
                                <div className={styles.seperator1}></div>
                                <a className={styles.linkMview} href="https://www.getfleek.app/terms-and-conditions" rel="noreferrer" target="_blank">Terms & Conditions</a>
                                {
                                    isLogin &&
                                    <>
                                        <div className={styles.seperator1}></div>
                                        <p onClick={toggleModal}>Sign Out</p>
                                    </>    
                                }
                            </div>
                            </>
                        }
                        {
                            type==="Account"&& mobileEdit && <ProfileMobComp setUserDetails={setUserDetails} userDetails={userDetails} setMobileEdit={setMobileEdit}/>
                        }
                        {
                            type==="Support" && <SupportComp/>
                        }
                        {
                            type==="Myorder" && <MyOrder/>
                        }
                    </>
                </div>
                {/* { type==="Account" &&
                    <>
                        <div className={styles.spacer}>
                        </div>
                        <MobileBottomNavBar type={"account"}/>
                    </>
                } */}
                {
                    showPopUp && <SignOutConfirmationPopUp toggleModal={toggleModal}/>
                }
            </>
        )
    }
    else{
        return(
            <div>
                {/* <NavBar image={userDetails.avatar_url}/> */}
                <div className={styles.desktopContainer}>
                    <div className={styles.parentContainer}>
                        <div className={styles.container1}>
                            <p className={styles.headText}>Account</p>
                            <AccountSideButton isActive={type==="Account"} type={'account'} icon={HumanIcon} title={"Account details"} subTitle={"Name and phone number"}  />
                            <AccountSideButton isActive={type==="Myorder"} type={'myorder'} icon={Bag} title={"My orders"} subTitle={"Track & view your recent orders"}  />
                            <AccountSideButton icon={FleekCoin} title={"FleekCoin & rewards"} subTitle={"Earn rewards, redeem vouchers & more"} extraText={"COMING SOON"} />
                            <AccountSideButton icon={SaveAddresss} title={"Saved address"} subTitle={"Add new address"} extraText={"COMING SOON"} />
                            <AccountSideButton isActive={type==="Support"} type={'support'} icon={QuestionMark} title={"Support"} subTitle={"Contact us"} />
                            <AccountSideButton toggleModal={toggleModal} icon={PowerOff} type={'login'} title={"Sign Out"} />
                            <p className={styles.useFullLinkText}>Useful links</p>
                            <div className={styles.otherLinkContainer}>
                                <a className={styles.link} href="https://www.getfleek.app/about-us" rel="noreferrer" target="_blank">About Us</a>
                                <div className={styles.seperator1}></div>
                                <a className={styles.link} href="https://www.getfleek.app/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a>
                                <div className={styles.seperator1}></div>
                                <a className={styles.link} href="https://www.getfleek.app/terms-and-conditions" rel="noreferrer" target="_blank">Terms & Conditions</a>
                            </div>
                        </div>
                        <div className={styles.container2}>
                        {
                            type==="Account" && <DesktopAccountDetail userDetails={userDetails} setUserDetails={setUserDetails}/>
                        }
                        {
                            type==="Support" && <SupportComp/>
                        }
                        {
                            type==="Myorder" && <MyOrder/>
                        }

                            {/* <DesktopAccountDetail/> */}
                            {/* <MyOrder/> */}
                            {/* <SupportComp/> */}
                        </div>
                    </div>
                </div>
                {
                    showPopUp && <SignOutConfirmationPopUp toggleModal={toggleModal}/>
                }
            </div>

        )
    }
}