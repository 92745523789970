import styles from "./FaqQueAns.module.css"

import React , {useState} from "react"
import minusSign from "../../asset/icons/minus.png"
import plusSign from "../../asset/icons/plus.png"


export const FaqQueAns = ({QueAnsObj})=>{
    const [showAnswer,setShowAnswer] = useState(false)
    return(
        <div className={styles.Container}>
            <div onClick={()=>{
                setShowAnswer((pre_state)=>(
                    !pre_state
                ))
            }} className={styles.ActionableArea}>
                <div className={styles.Question}>{QueAnsObj?.question}</div>
                {
                    showAnswer ? 
                      <img className={styles.Icon} src={minusSign} alt="minusSign"/>:
                      <img className={styles.Icon} src={plusSign} alt="plusSign"/>
                }
            </div>
            { showAnswer && <div className={styles.Answer}>
                {QueAnsObj?.answer}
            </div>}
        </div>
    )
}