import styles from "./InstallFleekComp.module.css"
import InstallFleek from "../../asset/images/InstallFleek.png"
import ArrowCircleRight from "../../asset/icons/ArrowCircleRight.png"


export const InstallFleekComp = ()=>{
    return(
        <div className={styles.card}>
            <div className={styles.flexColumn}>
                <p className={styles.text}>Now manage, share & much more on Fleek App</p>
                <div className={styles.installButton}>
                    <a href="https://getfleek.onelink.me/MDpg/l4qs48w8" rel="noreferrer" target="_blank" className={styles.installText}>INSTALL NOW</a>
                    <img src={ArrowCircleRight} className={styles.ArrowCircleRight} alt="ArrowCircleRight" />
                </div>
            </div>
            <img className={styles.InstallFleek} src={InstallFleek} alt="InstallFleek"/>
        </div>
    )
}