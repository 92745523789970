import React,{ useState ,useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./BundleDescriptionScreen.module.css"
import FleekIcon from "../../asset/icons/Brand_logo.png"
import RupeeCycle from "../../asset/icons/RupyeeCycle.png"

import BundleError from "../../asset/icons/bundle_error.png"
import FleekCoin from "../../asset/icons/fleek-coin-logo.png"

import { useParams } from "react-router-dom";
import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import { BrandAgeConBottomSheet,
     BundlePurchaseCard} from "../../components";
import { BrandImgInfoBanner } from "../../commonComponents";
import  Api from "../../utils/Api/Api";
import  Helper from "../../utils/Helper";
import cx from 'classnames';

export const BundleDescriptionScreen = ({isLoggedIn})=>{
    const { bundle_id } = useParams();
    const isMobile = Helper.isMobile();
    const navigate = useNavigate()

    let [bundleData,setBundleData] = useState({
        name:"",
        banner_img:"",
        logo:"",
        desc:"",
        plans:[],
        termNCond:[],
        faqList:[]
    })
    const [loading,setLoading] = useState(true);
    const [hasError,setError] = useState(false);
    const [showModal,setShowModal] = useState(false);
    const [modalData,setModalData] = useState({});

    const toggleModal = ()=>{
        setShowModal((pre_state)=>(!pre_state))
    }
    const AddToCart = async () => {
        try{

            if(!isLoggedIn) {
                localStorage.setItem('bundleName', bundle_id)
                return navigate('/login')
            }
            
            if(bundleDetails.is_already_availed_in_subscribe_flow){
                setModalData({title:`You already have a recurring subscription for ${bundleDetails.availed_subscription_text}`,
                     text: '',
                     button2: {
                        text: 'OKAY, GOT IT',
                        action:'',
                        cta:true
                     },
                     type:2,
                     image:RupeeCycle
                    })
                setShowModal(true);
                return;
            }
            const bundleId = bundleData?.[0]?.bundle?.id;
            const data = await Api.CheckBundleError({params:{
                bundleId
            }});
            if(data?.status === 200 ) {
                localStorage.setItem('bundleId', bundleId)
                navigate('/checkout');
            }
            else{
                setShowModal(true);
                setModalData({title:data?.error || `Oops! You already own SonyLiv subscription, you cannot buy this bundle now.`,
                     text: '',
                     button2: {
                        text: 'OKAY, GOT IT',
                        action:'',
                        cta:true
                     },
                     type:2,
                     image:BundleError
                    })
            }

        }
        catch(err){
            console.log('=========err===========', err)
        }
    }

    useEffect(()=>{
        const fetchBundleData = async ()=>{
            try {
                let { data } = await Api.BundleDetails({params:{
                    bundle_name:bundle_id
                }});
                setBundleData(data || [])
            }
            catch(err) {
                setError(true);
            }
            setLoading(false)
        }
        fetchBundleData();
    },[bundle_id])

    if(hasError) {
        return <ErrorFallback />
    }
    if(loading) {
        return <Loading />
    }
    
    if(!bundleData.length) {
        return
    }
    
    const {bundle: bundleDetails} = bundleData[0];

    const PriceView = () => {
        return (
            <div className={styles.priceCard}>
                <div className={cx(styles.flexRow,styles.padding125)}>
                    <p className={styles.priceCardText}>Worth</p>
                    <p className={cx(styles.priceCardText, styles.strike)}>₹{Math.round(bundleDetails.mrp_price)}/{bundleDetails.duration}mo</p>
                </div>
                <div className={cx(styles.flexRow, styles.padding200)}>
                    <p className={styles.priceCardText}>Get at</p>
                    <div className={styles.fleekCoinValue}>
                        <p >₹{Math.round(bundleDetails.discounted_cost)} +</p> 
                        <img src={FleekCoin} alt="FleekCoin"/>
                        <p className={styles.priceCardValue}>₹{Math.round(bundleDetails.max_coin_usable )}</p>
                    </div>
                </div>
                <div className={cx(styles.flexRow, styles.padding180)}>
                    <button onClick={() => AddToCart()} className={styles.payButton}>
                        BUY NOW
                    </button>
                </div>
            </div>  
        )
    }

   
    
        const bannerData = {
        banner_img:'https://res.cloudinary.com/dhmw8d3ka/image/upload/v1681954906/Bundles/Bundles_b5kgax.webp',
        logo: FleekIcon,
        name: bundleDetails.name,
        desc: bundleDetails.description,
        subscriptionCount: bundleDetails.subscription_count,
        image: bundleDetails.plan_images_list
    }
        const planLength = bundleData.length;

    return(
            <>
            {showModal && <BrandAgeConBottomSheet toggleModal={toggleModal} modalData={modalData} />}

            <div className={styles.brandPage}>
                <BrandImgInfoBanner isLoggedIn={isLoggedIn} brandData={bannerData} type={'bundle'} />
                <div className={styles.content}>
                <p className={styles.sectionTitle}>Plan</p>

                    <div className={styles.planSection}>
                        <div className={styles.planList}>
                            {
                                bundleData.map((ele,idx)=> <BundlePurchaseCard plan={ele.plan} length={planLength}  index={idx} key={idx}/>)
                            }
                        </div>
                        <div className={styles.verticalRuler}>

                        </div>
                        {
                            !isMobile ?PriceView() : ''
                        }
                    </div>
                    
                    
                    {/* <div className={styles.spacer}></div> */}

                </div>       
                {
                    isMobile ? PriceView(): ''
                }       
        </div>
        </>
    )
}