import styles from "./ReadMoreComp.module.css";
import React, { useState } from "react";

export const ReadMoreComp = (props)=>{
    const {text ,cut} = props;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return(
        <div className="text">
            {isReadMore ? text.slice(0, cut) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {text.length<= cut ? '' : isReadMore ? " ...SHOW MORE" : "   SHOW LESS"}
            </span>
        </div>
    )
}