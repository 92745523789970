import styles from "./OrderDetail.module.css"
import chevronLeft from "../../asset/icons/chevronLeft.png";
import forward from "../../asset/icons/whiteForwardCircle.png"
import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import  Api from "../../utils/Api/Api";
import React , {useState, useEffect} from "react"
import { useParams, useNavigate } from "react-router-dom";

import  Helper from "../../utils/Helper";

import { ProductPurchaseCard ,SubscriptionCouponDetailsCard , YoutubeLinkCard ,
    PaymentDetailsCard ,PaymentInstrumentDetailsCard} from "../../components";

export const OrderDetail = ()=>{
    const { order_id } = useParams();

    const [loading,setLoading] = useState(true);
    const [hasError,setError] = useState(false);
    const [orderDetail,setOrderDetail] = useState([]);
    const navigate = useNavigate()

    useEffect(()=>{
        const fetchCartData = async ()=>{
            try {
                let { data } = await Api.GetOrderDetails(order_id);
                setOrderDetail(data);

            }
            catch(err) {
                setError(true);
            }
            setLoading(false)
        }
        fetchCartData();
    },[])

    if(hasError) {
        return <ErrorFallback />
    }
    if(loading) {
        return <Loading />
    }

    const { payment, youtube_url, subscription_plan: {code_redemption_url, mrp_price} = {}, cost, updated_at, 
    product_digital, status = 'Order Placed', details, is_deep_tech, representation_id } = orderDetail;

    const paymentDetailsCard = {
        mrp_price,
        effective_cost: payment?.amount, 
        cost: cost - (cost - payment?.amount - details?.coin_value_used),
        coin_value_reserved: details?.coin_value_used,
        useFleekCoin: details?.coin_value_used,
        actual_discounted_cost: payment?.amount,
    }

    let date = Helper.dateFormat(updated_at);
    return(
        <>
            <div className={styles.headcontent}>
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <img onClick={() => navigate('/myorder')} className={styles.chevronLeft} src={chevronLeft} alt="chevronLeft"/>
                    <p className={styles.headText}>Order details</p>
                    <p onClick={() =>  navigate('/support')} className={styles.helpText}>HELP</p>
                </div>
                <div className={styles.detailContainer}>
                    <div className={styles.cardContainer}>
                        <ProductPurchaseCard cartData={orderDetail} orderPage={true} />
                        {product_digital && <SubscriptionCouponDetailsCard orderDetail={orderDetail} is_deep_tech={is_deep_tech}/>}
                        {youtube_url && <YoutubeLinkCard youtube_url={youtube_url}/>}
                        {product_digital && !is_deep_tech && <div className={styles.mobileButtonContainer}>
                            <button onClick={() => window.open(code_redemption_url, '_blank', 'noopener,noreferrer')} className={styles.nextButton}>
                                ACTIVATE NOW
                                <img className={styles.forward} alt="forward" src={forward}/>
                            </button>
                        </div>}
                    </div> 
                    <div className={styles.otherDetail}>
                        <div className={styles.orderDetail}>
                            <p className={styles.orderId}>Order ID: {representation_id}</p>
                            <p className={styles.orderPlaceText}>{status}</p>
                            <p className={styles.date}>on {date}</p>
                        </div>
                        <PaymentDetailsCard cartData={paymentDetailsCard} useFleekCoin={paymentDetailsCard.useFleekCoin}/>
                        {payment && <PaymentInstrumentDetailsCard payment = {payment} />}
                    </div>
                </div>       
            </div>     
        </>
    )
}