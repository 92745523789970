import React from "react";

import { CustomRadio } from "../CustomRadio/CustomRadio"
import styles from "./BrnadPlan.module.css"
import FleekCoin from "../../asset/icons/fleek-coin-logo.png"
import CheckLogo from "../../asset/icons/checkLogo.png"
import ExclamationIcon from "../../asset/icons/exclamationIcon.png"

import disableCheck from "../../asset/icons/disableCheck.png"
import disableExclamation from "../../asset/icons/disableExclamation.png"
import cx from 'classnames';
import Helper from "../../utils/Helper";

const isMobile = Helper.isMobile()



const BenefitFun = (benefits,show,subtext,disable)=>{

    return(
        <div className={ show ?cx(styles.BenefitsContainer,styles.show): styles.BenefitsContainer  }>
                    <div className={styles.VerticleSpacer}></div>
                    <div className={styles.Benefits}>
                        {benefits.map((ele,idx)=>(
                            <div className={styles.BenefitContain} key={idx}>
                                <img className={styles.CheckLogo} src={disable?disableCheck:CheckLogo} alt="check Logo"/>
                                <p className={ disable ?cx(styles.Benefit,styles.disable):styles.Benefit}>{ele}</p>
                            </div>    
                        ))}
                    </div>
                    <div className={styles.VerticleSpacer}></div>
                    {subtext && <div  className={styles.message}>
                        <img className={styles.ExclamationIcon} src={disable? disableExclamation: ExclamationIcon} alt="Exclamation Icon" />
                        <p className={disable ? cx(styles.MessageText,styles.disable): styles.MessageText}>{ subtext || ''}</p>
                    </div>}
        </div>
    )
}

export const DigitalBrandPlan = ({AddToCart, plan,index,setSelectedPlan,selectedPlanIdx})=>{
    const inputHandle = ()=>{
        setSelectedPlan(index)
    }
    const check = index===selectedPlanIdx
    let disable = plan.is_out_of_stock || plan.already_availed;
    // disable = true;
    
    const disableText = ()=>{
        if(plan.is_out_of_stock){
            return "Out of Stock"
        }
        else{
            return "Already availed"
        }
    }

    const CardConditionalStyle = ()=>{

        if(disable){
            return cx(styles.BrandCard,styles.disable)
        }
        else{
            return check ? cx(styles.BrandCard,styles.check):styles.BrandCard;
        }
    }
    const handleMobClick = ()=>{
        if(window.innerWidth<1280 && !disable){
            inputHandle()
        }
    }
    const benefits = plan.description.split("\n") || []
    return(
        <div onClick={handleMobClick} className={CardConditionalStyle()}>
            <div className={styles.PlanMainInfo}>
                <div className={styles.planTitle}>
                    <div className={styles.radioBtn}>
                        {disable?<div className={styles.mockCircle}></div> :<CustomRadio checked={index===selectedPlanIdx} />}
                    </div>
                    <div className={styles.Plan}>
                        <p className={ check ? cx(styles.Name, styles.selected) : styles.Name }>{plan.plan_name}</p>
                        <p className={disable ? cx(styles.Validity,styles.disable): styles.Validity}>{plan.duration} {plan.duration_type}</p>
                    </div>
                </div>
                {
                    disable ? 
                    <div className={styles.mobNewPriceDisplay}>
                        <p className={cx(styles.HeadPrice,styles.disable)} >₹ {Math.floor(plan.mrp_price)}</p>
                    </div>
                    :
                    <div className={styles.mobNewPriceDisplay}>
                    <div className={styles.newPrice}>
                        <p className={styles.markedPrice}>₹{plan.mrp_price}</p>
                        <p className={styles.sellingPrice}>₹{Math.floor(plan.discounted_cost)}</p>
                    </div>
                    {Math.floor(plan.max_coin_usable) ? <div className={styles.fleekCoinDetail}>
                        with <img className={styles.FleekCoinPrice} src={FleekCoin} alt="fleek coin logo"/> 
                        ₹{Math.floor(plan.max_coin_usable)}
                    </div> : ''}
                </div>}
                {/* <p className={disable ? cx(styles.HeadPrice,styles.disable): styles.HeadPrice} >₹ {Math.floor(plan.cost)}</p> */}
            </div>
            {
                disable && 
                <div className={styles.MobDisableText}>
                <p className={styles.disableText}>{disableText()}</p>
                        {/* <>
                            <p className={styles.PriceText}>Fleek Price:</p>
                            <p className={styles.PayPrice}>₹{Math.floor(plan.discounted_cost)} + </p>
                            {plan.max_coin_usable && <div className={styles.FleekPriceSec}>
                                <img className={styles.FleekCoin} src={FleekCoin} alt="fleek coin logo"/>
                                <p className={styles.FleekPrice}> ₹{plan.max_coin_usable}</p>
                            </div>}
                            <div className={styles.Seperator}></div>
                            <p className={styles.Discount}>{plan.new_discount_percentage}% OFF</p>
                        </> */}
                </div>
            }    
            <div className={styles.mobBenefit}>
                {
                    BenefitFun(benefits,check,plan.activation_type_message,disable)
                }
            </div>
            <div className={styles.deskBenefit}>
                {BenefitFun(benefits,true,plan.activation_type_message,disable)}
            </div>
            <div className={styles.DeskActions}>
                <div className={styles.PriceDetailDesk}>
                {
                    disable ?
                        <>
                            <p className={cx(styles.HeadPrice,styles.disable)} >₹ {Math.floor(plan.mrp_price)}</p>
                            <p className={styles.disableText}>{disableText()}</p>
                        </>
                        :
                        <>
                            <div className={styles.newPrice}>
                                {plan.new_discount_percentage ? <p className={styles.markedPrice}>₹{plan.mrp_price}</p> : ''}
                                <p className={styles.sellingPrice}>₹{Math.floor(plan.discounted_cost)}</p>
                            </div>
                            {Math.floor(plan.max_coin_usable) ? <div className={styles.fleekCoinDetail}>
                                with <img className={styles.FleekCoin} src={FleekCoin} alt="fleek coin logo"/> 
                                ₹{Math.floor(plan.max_coin_usable)}
                            </div> : ''}
                            {/* <p className={styles.PriceText}>Fleek Price:</p>
                            <p className={styles.PayPrice}>₹{Math.floor(plan.discounted_cost)} + </p>
                            {plan.max_coin_usable && <div className={styles.FleekPriceSec}>
                                <img className={styles.FleekCoin} src={FleekCoin} alt="fleek coin logo"/>
                                
                                <p className={styles.FleekPrice}> ₹{Math.floor(plan.max_coin_usable)}</p>
                            </div>}
                            <div className={styles.Seperator}></div>
                            <p className={styles.Discount}>{plan.new_discount_percentage}% OFF</p> */}

                        </>
                }    
                </div>
                {!disable && <div onClick={() => AddToCart({
                    subscription_plan:Number(plan.subscription_plan_id),
                    quantity:plan.quantity
                    })} className={styles.BuyNowButton}>BUY NOW</div>}
            </div>
        </div>
    )
}
