import {
    Link
  } from "react-router-dom";

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import styles from "./MarketPlaceCard.module.css"
import FleekCoin from "../../asset/icons/fleek-coin-logo.png"

export const MarketPlaceCard = (props)=>{
    const { service: {service_name, logo_url }, starts_from, discount_percentage, search_page_banner_url, vendor_web_path } = props.data;
    return(
        <Link to={'/brand/'+ vendor_web_path} className={styles.card}>
            <div className={styles.imageContainer}>
                <img className={styles.cardImage} src={search_page_banner_url} alt="cardImage" />
                {discount_percentage ? <div className={styles.cardSubTextContainer}>
                    <p className={styles.cardSubText}>UPTO {discount_percentage}% off</p>
                    <img className={styles.FleekCoin} src={FleekCoin} alt="FleekCoin"/>
                </div> : ''}
            </div>
            <div className={styles.detailContainer}>
                <img className={styles.logo} alt="logo" src= {logo_url} />
                <div className={styles.detail}>
                    <p className={styles.planName}
                        data-tooltip-id={service_name}
                        data-tooltip-content={service_name}
                        data-tooltip-place='bottom'
                    >{service_name}</p>
                    <p className={styles.planPrice}>{starts_from}</p>
                </div>
            </div>
            <Tooltip id={service_name} />
        </Link>
    )
}