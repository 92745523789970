import styles from "./OrderProductListDetailsCard.module.css"
import chevronRight from "../../asset/icons/blueChevronRight.png"


export const OrderProductListDetailsCard = ({orderDetails, OrderInfo, bundle})=>{
    return(
        <div className={styles.card}>
            <p className={styles.title}>Product</p>
            {
                orderDetails.map((od, i) => {
                    const {subscription_plan = {} } = od;

                    return (
                        <div key={od.id} >

                        <div className={styles.productDetail}>
                            <img src={subscription_plan.image_url}  alt="productLogo" className={styles.logo}/>
                            <div className={styles.productDesc}>
                                <p className={styles.name}>{subscription_plan.service_name}</p>
                                <p className={styles.planName}>{bundle? subscription_plan.plan_name: subscription_plan.display_data}</p>
                            </div>
                            <div onClick={() => OrderInfo(i)}  className={styles.activateButton}>
                                <p className={styles.activateText}>ACTIVATE</p>
                                <img className={styles.chevronRight} src={chevronRight} alt="chevronRight" />
                            </div>

                        </div>
                        { i < orderDetails.length -1 ? <div className={styles.horizontalBar}></div>: ''}

                        </div>
                        
                        
                    )
                })
            }
        </div>
    )
}