import styles from "./MyOrder.module.css"
import axios from "axios"
import React ,{useState, useEffect} from "react";
import chevronLeft from "../../asset/icons/chevronLeft.png";
import searchIcon from "../../asset/icons/searchIcon.png"
import throne from "../../asset/images/throne.png"
import FleekIcon from "../../asset/icons/FleekIcon.png"
import { NavBar } from "../../commonComponents";
import  Api from "../../utils/Api/Api";
import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import { FCSavedCard , OrderProductDetailsCard } from "../../components";
import cx from 'classnames';
import crossIcon from "../../asset/icons/crossCircle.png"
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const MyOrder = ({changeScreen})=>{
    const navigate = useNavigate()
    const [searchValue,setSearchValue] = useState("");
    const [orders,setOrders] = useState([]);
    const [filteredOrders,setfilteredOrders] = useState([]);

    // const [upcomingOrders,setUpcomingOrders] = useState([]);

    const [usedFleekCoin,setUsedFleekCoin] = useState(0);
    
    const [loading,setLoading] = useState(true);
    const [hasError,setError] = useState(false);

    const handleChange = (e)=>{

        if(e.target.value) {
            const filterOrders= orders.filter((o)=> {
                return o.product_name.toLowerCase().includes(e.target.value.toLowerCase())
            })
            setfilteredOrders(filterOrders);
        }
        else{
            setfilteredOrders(orders);
        }
        
        setSearchValue(e.target.value)
    }
    const eraseInput = (e)=>{
        handleChange(e)
        setSearchValue("");
    }
    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                let [{data}, {data: orders}] = await Promise.all([
                     Api.FleekCoinsUsed(),
                     Api.GetOrderDetails(),
                    //  Api.GetOrderDetails({show_upcoming_orders:1})
                   ]);
                   if(data){
                    setUsedFleekCoin(data.sum || 0);
                   }

                   if(orders && orders.results?.length) {
                    setOrders(orders.results);
                    setfilteredOrders(orders.results);
                   }

                //    if(upcomingOrders && upcomingOrders.results?.length) {
                //     setUpcomingOrders(upcomingOrders.results);
                //    }
            }
            catch(err) {
                setError(true);
            }
            setLoading(false)
        }
        fetchData();
    },[])

    
    if(hasError) {
        return <ErrorFallback />
    }
    if(loading) {
        return <Loading />
    }

    return(
        <div>
            <div className={styles.header}>
                <p className={styles.headText}>My Orders</p>
                <img onClick={() => navigate(-1)} className={styles.chevronLeft} src={chevronLeft} alt="chevronLeft"/>
            </div>
            {
                orders.length>0 ?
                    <div className={styles.detailContainer}>
                        <div className={styles.cardContainer}>
                            <div className={styles.inputWrapper}>
                                <img className={styles.searchIcon} src={searchIcon} alt="searchIcon"/>
                                <input className={styles.input}
                                    onChange={handleChange}
                                    placeholder="Search your orders"
                                    type="TEXT"
                                    value={searchValue}
                                    />
                                <img 
                                    onClick={eraseInput}
                                    name="email"
                                    className={searchValue.length>0 ?cx(styles.crossSign,styles.display): styles.crossSign}
                                    src={crossIcon} alt="crossIcon" />
                            </div>
                            {filteredOrders.length>0 && <FCSavedCard usedFleekCoin={usedFleekCoin} />}
                            {
                                filteredOrders.length>0 ? filteredOrders.map((o) => {
                                    return <OrderProductDetailsCard key={o.id} order={o} />
                                }):
                                <p className={styles.notFoundText}>
                                    Sorry! Order not found
                                </p> 
                            }
                        </div>
                    </div>
                :
                    <div className={styles.emptyOrder}>
                        <img className={styles.throneImg} src={throne} alt="throne"/>
                        <p className={styles.text1}>Throne feels empty?</p>
                        <p className={styles.text2}>One day this throne will follow your orders</p>
                        <Link className={styles.button} to="/marketplace">EXPLORE MARKETPLACE</Link>
                    </div>
            }  
        </div>       
    )
}