import styles from "./PlanBenefit.module.css"
import React , {useState} from "react"
import chevronDown from "../../asset/icons/chevronDown.png"
import chevronUp from "../../asset/icons/chevronUp.png"



export const DigitalBrandBenefit = ({benefits})=>{
    const [showBenefit,setShowBenefit] = useState(false)
    console.log("benefits",benefits)
    benefits = benefits.split(/\r?\n/);
    console.log("benefits",benefits)
    return(
        <div className={styles.BenefitCard}>
            <div onClick={()=>{
                setShowBenefit((pre_state)=>(!pre_state))
            }} className={styles.ActionArea}>
                <div className={styles.BenefitTitle}>
                    Benefits with Fleek
                </div>
                <div className={styles.checvronDiv}>
                    {showBenefit?
                        <img className={styles.chevronDownClass} src={chevronUp} alt="Chevron Up" />
                        : <img className={styles.chevronDownClass} src={chevronDown} alt="Chevron Down" />}
                </div>
            </div>
            {showBenefit && 
            <ul className={styles.benefitTextContainer}>
                {benefits.map((ele,idx)=>(
                    <li key={idx} className={styles.singleBenefit}>
                        {ele}
                    </li>
                ))}
            </ul>}
        </div>
    )
}    