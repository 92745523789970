import styles from "./WhatsAppPopUp.module.css"
import React,{ useState ,useEffect } from "react";
import messageIcon from "../../asset/icons/messageLogo.png"

export const WhatsAppPopUp = ({toggle})=>{
    const [second,setSecond] = useState(3);
    const stopPropagation = (e)=>{
        e.stopPropagation();
    }
    useEffect(()=>{
        const timeout = setTimeout(() => {
            if(second!==0){
                setSecond((pre_state)=>(pre_state-1))
            }
        }, 1000);
        return (()=>{
            clearTimeout(timeout)
        })
    })
    return(
        <div onClick={toggle} className={styles.overlay}>
            <div onClick={stopPropagation} className={styles.popUp}>
                <div className={styles.redirectingText}>
                    <p>Redirecting to WhatsApp in...</p>
                    <p className={styles.secondText}>{second} sec</p>
                </div>
                <div className={styles.infoContainer}>
                    <p className={styles.nextStepText}>In the next steps...</p>
                    <div className={styles.nextInstruction}>
                        <img className={styles.messageLogo} src={messageIcon} alt="messageIcon"/>
                        <p>We will open your WhatsApp. Tap send. Click on the link to login.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}