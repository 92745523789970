import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { NavBar } from "../../commonComponents";
import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import styles from "./Marketplace.module.css";
import FleekIcon from "../../asset/icons/FleekIcon.png";
import NoSearchResult from "../../asset/images/NoSearchResult.png";

import {
  MarketPlaceCard,
  BundleMarketCard,
  SearchComp,
} from "../../components";
import Api from "../../utils/Api/Api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import "react-horizontal-scrolling-menu/dist/styles.css";

import { MobileBottomNavBar } from "../../commonComponents";
import Helper from "../../utils/Helper";

function Arrow() {
  return <div className={styles.hide}></div>;
}

export const Marketplace = ({ isLoggedIn }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const [brandImage, setBrandImage] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [slideNumber, setSlideNumbar] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const isMobile = Helper.isMobile();
  const [showMobileSearch, setShowMobileSerach] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const toggleMobSearch = () => {
    setShowMobileSerach((pre_state) => !pre_state);
  };
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: slideNumber,
    prevArrow: <Arrow />,
    nextArrow: <Arrow />,
    afterChange(currentSlide) {
      setSlideNumbar(currentSlide);
    },
  };

  const fetchMarketPlaceData = async (page_size = 20, searchFlow) => {
    try {
      const promiseArr = [
        Api.MarketPlaceData({
          service_name: searchValue ? searchValue : undefined,
          page: !searchFlow ? page : 1,
          digital_only: 1,
          page_size,
        }),
      ];
      if (page === 1 && !searchFlow) {
        promiseArr.push(Api.MarketPlaceBanner({ os: "web" }));
        promiseArr.push(Api.MarketPlaceBundle({ os: "web" }));
      }
      const [{ data }, banner, bundle] = await Promise.all(promiseArr);

      if (
        banner &&
        banner.data &&
        banner.data.Marketplace &&
        banner.data.Marketplace.length
      ) {
        let bannerImages = banner.data.Marketplace.map((b) => {
          return {
            web_banner_url: !isMobile ? b.web_banner_url : b.banner_url,
            vendor_web_path: b.vendor_web_path,
            deep_link_url: b.deep_link_url,
          };
        });
        bannerImages = bannerImages.filter(
          (ele) => ele.web_banner_url !== null
        );
        setBrandImage(bannerImages);
      }
      if (bundle?.data) {
        setBundleData(bundle.data);
      }
      if (data && data.results && data.results.length) {
        if (!searchFlow) {
          setBrandData([...brandData, ...data.results]);
          if (!data.next) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setPage(page + 1);
        } else {
          if (!data.next) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setBrandData([...data.results]);
        }
      } else {
        if (!data?.next) {
          setHasMore(false);
        }
        setBrandData([]);
      }
    } catch (err) {
      console.log("=======err==========", err);
      setError(true);
    }
    setLoading(false);
  };
  const sliderRef = useRef(null);
  sliderRef.current?.slickGoTo(slideNumber);
  const ExploreMarketPlace = () => {
    setSearchValue("");
    if (isMobile) {
      toggleMobSearch();
    }
  };
  useEffect(() => {
    fetchMarketPlaceData(20);
  }, []);

  useEffect(() => {
    if (brandImage && brandImage.length) {
      const callEveryInterval = setTimeout(() => {
        setSlideNumbar((pre_slide) => (pre_slide + 1) % brandImage.length);
      }, 5000);
      return () => {
        clearTimeout(callEveryInterval);
      };
    }
  });

  const bundleView = () => {
    return bundleData.map((b) => {
      return (
        <BundleMarketCard
          key={b.id}
          plan={{
            planName: b.name,
            mrp_price: b.mrp_price,
            category: b.new_discount_percentage,
            max_coin_usable: b.max_coin_usable,
            discounted_cost: b.discounted_cost,
            image: b.plan_images_list,
            id: b.id,
            color: b.details?.colour_code,
            bundle_web_path: b.bundle_web_path,
          }}
        />
      );
    });
  };
  useEffect(() => {
    fetchMarketPlaceData(20, "search");
  }, [searchValue]);

  if (hasError) {
    return <ErrorFallback />;
  }
  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.marketplace}>
      {isMobile && <NavBar fixedNavBar={true} isLoggedIn={isLoggedIn} />}

      <div className={styles.headcontent}>
        <div className={styles.sliderWrapper}>
          {brandImage && brandImage.length ? (
            <Slider ref={sliderRef} {...settings}>
              {brandImage.map((ele, idx) => (
                <a
                  href={
                    ele.deep_link_url
                      ? ele.deep_link_url
                      : `/brand/${ele.vendor_web_path}`
                  }
                  target={ele.deep_link_url ? "_blank" : ""}
                  onClick={() => {
                    if (ele.deep_link_url) {
                      // If deep_link_url exists, prevent the default behavior of the link
                      // to prevent the page from navigating twice (once with window.open and once with navigate)
                      window.open(ele.deep_link_url, "_blank");
                    } else if (ele.vendor_web_path) {
                      // If deep_link_url doesn't exist but vendor_web_path exists, use navigate
                      navigate(`/brand/${ele.vendor_web_path}`);
                    }
                  }}
                >
                  <img
                    key={idx}
                    className={styles.bannerImage}
                    alt="bannerImage"
                    src={ele.web_banner_url}
                  />
                </a>
              ))}
            </Slider>
          ) : (
            ""
          )}
        </div>
        <div className={styles.dotContainer}>
          {brandImage.map((_, idx) => (
            <div
              key={idx}
              onClick={() => {
                setSlideNumbar(idx);
              }}
              className={
                idx === slideNumber ? cx(styles.dot, styles.active) : styles.dot
              }
            ></div>
          ))}
        </div>
      </div>
      {/* <div className={styles.restContent}>
                
            </div> */}
      <div className={styles.restContent}>
        <div className={styles.BundleExploreContainer}>
          <p className={styles.sectionTitle}>Exclusive bundles on Fleek 🤑</p>
          <p
            onClick={() => navigate("/bundle")}
            className={styles.sectionSeeAll}
          >
            SEE ALL
          </p>
        </div>
        <p className={styles.sectionSubTitle}>Buy more for less</p>

        {<ScrollMenu>{bundleView()}</ScrollMenu>}
        {/* <div className={styles.scrollHoriBorder}> 
                    <div className={styles.scrollHori}>

                    </div>
                </div> */}

        <div className={styles.tempSearchContainer}>
          {!showMobileSearch && (
            <p className={styles.sectionTitle}>Explore all brands</p>
          )}
          <SearchComp
            showMobileSearch={showMobileSearch}
            toggleMobSearch={toggleMobSearch}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
        </div>
        <InfiniteScroll
          dataLength={brandData.length} //This is important field to render the next data
          next={fetchMarketPlaceData}
          hasMore={hasMore}
          // loader={<h4></h4>}
          endMessage={<p style={{ textAlign: "center" }}></p>}
          refreshFunction={() => brandData}
          pullDownToRefresh
          pullDownToRefreshThreshold={20}
        >
          <div
            className={
              brandData.length ? styles.cardContainer : styles.noResultContainer
            }
          >
            {brandData.length ? (
              brandData.map((d) => {
                return <MarketPlaceCard data={d} key={d.vendor_id} />;
              })
            ) : (
              <div className={styles.container1}>
                <img
                  src={NoSearchResult}
                  alt="NoSearchResult"
                  className={styles.NoSearchResult}
                />
                <p className={styles.noResult}>No results found!</p>
                <button onClick={ExploreMarketPlace} className={styles.button1}>
                  EXPLORE MARKETPLACE
                </button>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
      <div className={styles.spacer}></div>
      {/* <MobileBottomNavBar isLoggedIn={isLoggedIn} type={"marketPlace"} /> */}
    </div>
  );
};
