import styles from "./OrderProductDetailsCard.module.css";
import chevronRight from "../../asset/icons/chevronRight.png";
import { Link } from "react-router-dom";

export const OrderProductDetailsCard = ({order})=>{
    return(
        <Link style={{textDecoration: 'none'}} to={'/orderdetail/'+ order.id} className={styles.card}>
            <div className={styles.headerDetail}>
                <p className={styles.title}>
                    {order.status}
                </p>
                <img src={chevronRight} alt="chevronRight" className={styles.chevronRight} />
            </div>
            
            <div className={styles.horizonatalBar}>
            </div>
            <div className={styles.orderDetail}>
                <img className={styles.icon} alt="icon" src={order.subscription_plan.image_url} />
                <div className={styles.orderName}>
                    <p className={styles.name}>{order.subscription_plan.service_name}</p>
                    <p className={styles.planType}>{order.product_digital ? order.subscription_plan.plan_name: order.product_name}</p>
                </div>
            </div>
        </Link>
    )
}