import styles from "./style.module.css";
import chevronUp from "../../asset/icons/chevronUp.png"
import chevronRight from "../../asset/icons/chevronRight.png"
import chevronLeft from "../../asset/icons/chevronLeft.png"
import { useNavigate } from "react-router-dom";

import React ,{useState,useRef,useEffect} from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import cx from 'classnames';


function NextArrow(props) { 
    const navigate = useNavigate()

    const { hide, onClick } = props;
    return (
      <div className={hide?cx(styles.arrow,styles.hide):cx(styles.arrow,styles.right)} onClick={onClick}>
            <img
            alt='D2C'
            onClick={() => navigate(-1)}
                src={chevronRight}
        />
      </div>
    );
}

function PrevArrow(props) { 
    const { hide, onClick } = props;
    return (
        <div className={hide?cx(styles.arrow,styles.hide): cx(styles.arrow,styles.left)} onClick={onClick}>
            <img
            alt='D2C'
                src={chevronLeft}
            />
        </div>
    );
}


export const D2CSubscriptionBoxDetailScreenProductCard = ({info,toggleCard})=>{
    const [slideNumber,setSlideNumbar] = useState(0)
    const imagesArray = info?.images || ["https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJ1EisAWkP1DlFr8ZzDWolz9u_z-UqrEmcXtfJNelQ&s",
                                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB8r_BgXvMW02rlUFnAoeOgTMsKm-X9_2TKg&usqp=CAU",
                                        "https://images.unsplash.com/photo-1575881875475-31023242e3f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8M3x8fGVufDB8fHx8&w=1000&q=80",
                                        "https://images.unsplash.com/photo-1581224463294-908316338239?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NXx8fGVufDB8fHx8&w=1000&q=80"]
    let settings = {
        dots: false,
        infinite: false,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: slideNumber,
        prevArrow: <PrevArrow hide={slideNumber===0} />,
        nextArrow: <NextArrow hide={slideNumber===imagesArray.length-1} />,
        afterChange(currentSlide){
            setSlideNumbar(currentSlide)
          }
      };
    
    const sliderRef = useRef(null);
    sliderRef.current?.slickGoTo(slideNumber);

    useEffect(()=>{
        const callEveryInterval = setTimeout(() => {
            setSlideNumbar((pre_slide)=>((pre_slide+1)%imagesArray.length))
        }, 2000);
        return (()=>{
            clearTimeout(callEveryInterval)
        })
    })
      
    return (
        <div className={styles.card}>
            <div className={styles.info}>
                <div className={styles.heading}>
                    {info?.name || "Kitchen Towel"}
                </div>
                <img onClick={toggleCard} className={styles.chevron} src={chevronUp} alt="chevron"/>
            </div>
            <div className={styles.sliderWrapper}>
                <Slider ref={sliderRef} {...settings}>
                    {
                        imagesArray.map((ele,idx)=>(
                            <img key={idx} className={styles.sliderImg} src={ele} alt="sliderImg"></img>
                        ))
                    }
                </Slider> 
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.detail}>
                    {info?.detail || "Eco Friendly Reusable Kitchen Towel Roll (20 Sheets). Strong and durable single ply bamboo sheets."}
                </div>
                <p className={styles.price}>₹ {info?.price || "350"}</p>
            </div>
        </div>
    )
}


