import styles from "./Order.module.css"
import { PaymentSuccessModal ,OrderProductListDetailsCard ,PaymentDetailsCard,PaymentFailureModal
     ,PaymentInstrumentDetailsCard  } from "../../components"
import CrossSign from "../../asset/icons/whiteCross.png"
import CongratsImage from "../../asset/icons/congratsImage.png"
import forward from "../../asset/icons/whiteForwardCircle.png"
import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import cx from 'classnames';

import  Api from "../../utils/Api/Api";

import { useNavigate, useSearchParams } from "react-router-dom";
import React , {useState, useEffect} from "react"


export const Order = (props)=>{
    const [showModal,setShowModal] = useState(false);
    const [searchParams] = useSearchParams();
    const order_id = searchParams.get("order_id")
    const [loading,setLoading] = useState(true);
    const [hasError,setError] = useState(false);
    const [orderDetails,setOrderDetails] = useState({});

    const navigate = useNavigate()
    const toggleModal = ()=>{
        localStorage.setItem('order_id', order_id)
        setShowModal((pre_state)=>(!pre_state))
    }

    useEffect(()=>{
        const fetchCartData = async ()=>{
            try {
                let params = {
                    order_id
                  }
                let { data } = await Api.GetOrderConfirmation(params);

                let paymentId= {
                    payment_order_id: data.payment_id
                }
                let { data: orderDetail } = await Api.GetOrderDetailsFromPaymentId(paymentId);
                if(orderDetail && orderDetail.length){
                    setOrderDetails(orderDetail[0]);
                    if(orderDetail[0]?.payment?.status === 'paid') {
                        if(localStorage.getItem('order_id') === order_id) {
                            setShowModal(2);
                        }
                        else{
                            setShowModal(1);
                        }
                        
                    }
                    else{
                        setShowModal(0);
                    }
                }
                else{
                    setShowModal(0);
                }

            }
            catch(err) {
                setError(true);
            }
            setLoading(false)
        }
        fetchCartData();
    },[])
    
    if(hasError) {
        return <ErrorFallback />
    }
    if(loading) {
        return <Loading />
    }

    const {new_ordered_items, payment, amount_saved_dict, total_amount, details, bundle:bundleDetails} = orderDetails;
    let bundle = bundleDetails ? true : false;
    const OrderInfo = (i) => {
        navigate(`/orderdetail/${new_ordered_items[i? i :0 ].id}`)
    }

    const paymentDetails = {mrp_price: bundle? bundleDetails.mrp_price: new_ordered_items[0]?.subscription_plan?.mrp_price,
         useFleekCoin: details?.coin_value_used,
         actual_discounted_cost: payment.amount,
         coin_value_reserved: details?.coin_value_used,
         cost:bundle ? bundleDetails.mrp_price - amount_saved_dict.amount_saved + details?.coin_value_used : new_ordered_items[0]?.cost - (total_amount - payment.amount),
        }

        if(showModal === 0 ) {
            return <PaymentFailureModal toggleModal={toggleModal}/>
        }
        
    return(
        <div>
           
            {showModal === 1 && <PaymentSuccessModal payment={payment} amount_saved_dict= {amount_saved_dict} toggleModal={toggleModal}/>}
            {
            <>
                <div className={styles.headcontent}>
                    {/* <NavBar/> */}
                </div>
                <div className={(showModal === 1 || showModal === 0) ? cx(styles.content, styles.opacity25): styles.content}>
                    <div className={styles.header}>
                        <img onClick={()=>{
                            navigate('/marketplace')
                        }} className={styles.crossSign} src={CrossSign} alt="CrossSign"/>
                        <p className={styles.headText}>Order Confirmed</p>
                        <p onClick={() =>  navigate('/support')} className={styles.helpText}>HELP</p>
                    </div>
                    <div className={styles.congratsContainer}>
                        <img className={styles.CongratsImage} src={CongratsImage} alt="CongratsImage" />
                        <p className={styles.congratsHead}>Congratulations!</p>
                        <p className={styles.congratsSubhead}>We received your order for {new_ordered_items?.length} items</p>
                    </div>
                    <div className={styles.detailContainer}>
                        <div className={styles.cardContainer}>
                            <OrderProductListDetailsCard bundle={bundle} OrderInfo={OrderInfo} orderDetails={new_ordered_items}/>
                        </div>
                        <div className={styles.otherDetail}>
                            <PaymentDetailsCard cartData={paymentDetails} useFleekCoin={paymentDetails.useFleekCoin} />
                            <PaymentInstrumentDetailsCard payment={payment}/>
                            <div className={styles.mobileButtonContainer}>
                                <button className={styles.nextButton}
                                    onClick = {() => bundle? navigate('/myorder') : OrderInfo()}
                                >
                                    SEE ORDER DETAILS
                                    <img className={styles.forward} alt="forward" src={forward}/>
                                </button>
                            </div>
                        </div>
                    </div>   
                </div>
            </>
            }
        </div>
    )
}