import React ,{useState, useEffect} from "react";
import  Api from "../../utils/Api/Api";
import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";

import styles from "./CoinNWallet.module.css"

import FleekCoin from "../../asset/icons/fleek-coin-logo.png"
// import FleekWallet from "../../asset/icons/fleekWallet.png"

export const CoinNWallet = ({isLoggedIn})=>{
    const [fleekCoins,setFleekCoins] = useState([]);
    const [loading,setLoading] = useState(false);
    const [hasError,setError] = useState(false);

    useEffect(()=>{
        const fetchCartData = async ()=>{
            try {
                let [{data:fleekCoins}] = await Promise.all([ Api.GetFleekCoins()]);     
                setFleekCoins(fleekCoins);
            }
            catch(err) {
                console.log('=========')
                setError(true);
            }
            setLoading(false)
        }
        if(isLoggedIn)
        fetchCartData();
    },[])

    if(hasError) {
        return <ErrorFallback />
    }
    if(loading) {
        return <Loading />
    }
    return(
        
        <div className={styles.container}>
            <img className={styles.FleekCoin} src={FleekCoin} alt="FleekCoin"/>
            <p className={styles.priceText}>₹ {fleekCoins?.coin_value_in_inr || 0}</p>
            {/* <div className={styles.verticalBar}></div>
            <img className={styles.FleekWallet} src={FleekWallet} alt="FleekWallet" />
            <p className={styles.priceText}>₹ 30</p> */}
        </div>
    )
}