import styles from "./Error.module.css"
import errorImage from "../../asset/images/errorImage.png";
import { useNavigate } from "react-router-dom";


export const ErrorFallback = (error = '')=>{
    const navigate = useNavigate();

    const message = error?.message || error?.error?.message;
    console.log('err', message)
    return(
        // <div>
        //     <p style={{color: 'white'}}>Something went wrong:</p>
        //     <pre style={{color: 'red'}}>{message || ''}</pre>
        // </div>
        <div className={styles.errorScreen}>
            <div className={styles.content}>
                <img className={styles.errorImage} src={errorImage} alt="errorImage" />
                <p className={styles.headerText}>Oops, some error occured!</p>
                <p className={styles.subText}>Please try to reload the app.</p>
                <button onClick={() => navigate('/marketplace')} className={styles.button} >RELOAD APP</button>
            </div>
        </div>
    )
}