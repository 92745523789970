import axios from 'axios';

const Api = {
    async BrandDetails (payload) {
        return axios.get('/marketplace/v3/get_digital_plans_from_vendor', payload );
      },
      async BundleDetails (payload) {
        return axios.get('marketplace/v2/get_bundle_details', payload );
      },
      async CheckBundleError (payload) {
        return axios.get('marketplace/check_bundle_errors', payload );
      },
      async LoginWhatsapp () {
        return axios.get('/users/get_otpless_intent')
      },
      async GetOtp (payload) {
        return axios.post('/users/login', payload);
      },
      async VerifyOtp (payload) {
        return axios.post('/users/verify_otp', payload);
      },
      async SignUp (payload) {
        return axios.post('/users/register', payload);
      },
      async MarketPlaceBanner (params={}) {
        return axios.get('/marketplace/v3/get_app_banners',{params})
      },
      async MarketPlaceBundle (params={}) {
        return axios.get('/marketplace/v2/get_section_product_vendor_data?section_id=9',{params})
      },
      async BundleList (params={}) {
        return axios.get('/marketplace/v2/get_bundles_list',{params})
      },
      async MarketPlaceData (params={}) {
        return axios.get('/marketplace/v2/vendor_v2/', {params})
      },
      async EmptyCart () {
        return axios.get('/marketplace/cart/remove_all/')
      },
      async AddToCart (payload) {
        return axios.post('/marketplace/cart/', payload)
      },
      async GetCartItems () {
        return axios.get('/marketplace/cart/')
      },
      async PlaceOrder (payload) {
        return axios.post('/marketplace/v3/orders/', payload)
      },
      async BundleCheckout (params) {
        return axios.get('marketplace/v2/get_order_amount_details', {params} );
      },
      async GetFleekCoins () {
        return axios.get('/wallet/get_user_coins')
      },
      async GetPaymentLink (payload) {
        return axios.post('/payments/v3/create_payment_order', payload)
      },
      async GetOrderConfirmation (params) {
        return axios.get('/payments/v3/get_juspay_order_status', {params})
      },
      async GetOrderDetails (params) {
        return axios.get(`/marketplace/v3/my_orders/${params ? params + '/' : ''}`)
      },
      async GetOrderDetailsFromPaymentId (params) {
        return axios.get('/payments/v3/get_orders_from_payment_id', {params})
      },
      async GetUserDetails () {
        return axios.get('/users/get_profile')
      },
      async FleekCoinsUsed () {
        return axios.get('marketplace/v3/sum_of_fleek_coins_used')
      },
      async AgeRestrictionUpdate (payload) {
        return axios.post('/marketplace/add_age_restrictions_logs', payload)
      },
      async UpdateProile (payload) {
        const form = new FormData();
        if(payload.image_file)
        form.append('image_file', payload.image_file);

        if(payload.name)
        form.append('name', payload.name);

        if(payload.email)
        form.append('email', payload.email);

        if(payload.dob)
        form.append('dob', payload.dob);

        if(payload.gender)
        form.append('gender', payload.gender);

        return axios.post('/users/v2/edit_profile', form);
      },
      async GetAvailableCoupon (payload) {
        return axios.post('/misc/v2/get_available_coupon', payload)
      },
      async ValidateCoupon (payload) {
        return axios.post('/misc/v2/validate_coupon_code', payload)
      },
      async GetOrderAmount (params) {
        return axios.get('/marketplace/v2/get_multiple_order_amount_details', {params})
      },
      async CreateJuspayId () {
        return axios.get('/users/get_juspay_customer_id_for_user')
      },
}


export default Api;