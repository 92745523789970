import styles from "./PaymentFailureModal.module.css";
import React  from "react"
import CrossSign from "../../asset/icons/whiteCross.png"
import PaymentFailure from "../../asset/images/PaymentFailure.png"
import { useNavigate } from "react-router-dom";


export const PaymentFailureModal = ()=>{
    const navigate = useNavigate();
    return(
        <div className={styles.overlay}>
            <div className={styles.popUp}>
                <img className={styles.paymentFail} src={PaymentFailure} alt="PaymentFailure"/>
                <img onClick={() => navigate('/checkout')} className={styles.CrossSign} src={CrossSign} alt="CrossSign" />
                <p className={styles.title1}>Payment Failed</p>
                <p className={styles.title2}>Your payment did not go through.</p>
                <button onClick={() => navigate('/checkout')} className={styles.button}>TRY PAYMENT AGAIN</button>
            </div>
        </div>    
    )
}