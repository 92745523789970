import styles from "./ProfileMobComp.module.css"
import React,{ useState } from "react";
import chevronLeft from "../../asset/icons/chevronLeft.png";
import cx from 'classnames';
import  Api from "../../utils/Api/Api";
import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import crossIcon from "../../asset/icons/crossCircle.png"


export const ProfileMobComp = ({setMobileEdit, userDetails, setUserDetails})=>{
    const [showBottomSheet,setShowBottomSheet] = useState(false);
    const [loading,setLoading] = useState(false);
    const [hasError,setError] = useState(false);
    
    const toggleBottomSheet = ()=>{
        setShowBottomSheet((pre_state)=>(!pre_state))
    }

    const clearInput = (e)=>{
        const name = e.target.name;
        setUserDetails((pre_state)=>{
            return {...pre_state,[name]:""}
        })
    }

    const handleChange = (e, img)=>{
        const name = e.target.name;
        const value = !img ? e.target.value : e.target.files[0];
        setUserDetails((pre_state)=>{
            return {...pre_state,[name]:value}
        })
    }

    const handleChipChange = (e)=>{
        const name = e.target.getAttribute('name')
        const value = e.target.getAttribute('value')
        setUserDetails((pre_state)=>{
            return {...pre_state,[name]:value}
        })
    }

    const SaveChanges = async () => {
        try {
            const payload = {
                    name: userDetails.name,
                    email: userDetails.email,
                    dob: userDetails.dob ? userDetails.dob : undefined,
                    gender: userDetails.gender ? userDetails.gender : undefined,
                    image_file: userDetails.image_file ? userDetails.image_file : undefined
            }
            await Api.UpdateProile(payload);
            setMobileEdit(false)
        }
        catch(err) {
            setError(true);
            console.log('=======err=========', err)
        }
    }


    if(hasError) {
        return <ErrorFallback />
    }
    if(loading) {
        return <Loading />
    }
    const {name,email, avatar_url, dob, gender, phone_number} = userDetails;
    return(
        <div>
            <div className={styles.header}>
                <p className={styles.headText}>Profile</p>
                <img onClick={() => setMobileEdit(false)} className={styles.chevronLeft} src={chevronLeft} alt="chevronLeft"/>
            </div>
            <div className={styles.otherDetail}>
                <div className={styles.userImgContainer}>
                    {
                        userDetails.image_file || avatar_url ? 
                            <img key={new Date().getTime()} className={styles.userMainImg} src={
                                userDetails.image_file ? 
                                URL.createObjectURL(userDetails.image_file): avatar_url
                            } alt="CameraIcon"/>
                            :
                            <p className={styles.userImgLetter}>{name.slice(0,1).toUpperCase()}</p>
                    }
                    {/* <img className={styles.CameraIcon} src={
                        userDetails.image_file ? 
                        URL.createObjectURL(userDetails.image_file): avatar_url
                    } alt="CameraIcon"/> */}
                    <input 
                        onChange = {(e) => handleChange(e,1)}
                        accept=".png, .jpg"
                        type="file" id="file-input" name="image_file" />
                </div>
                <div className={cx(styles.inputBundle,styles.firstBundle)}>
                    <p className={styles.inputTitle}>Name</p>
                    <div className={styles.inputWrapper}>
                        <input placeholder="Enter Your Name" required name='name' onChange={handleChange} value={name} type="text" className={styles.input}/>
                        <img onClick={clearInput} name='name' className={name.length>0 ?cx(styles.crossSign,styles.display): styles.crossSign} src={crossIcon} alt="crossIcon" />
                    </div>
                </div>
                <div className={styles.inputBundle}>
                    <p className={styles.inputTitle}>Phone Number</p>
                    <div className={cx(styles.inputWrapper,styles.disable)}>
                        <p className={styles.countryCode}>+91</p>
                        <div readOnly className={cx(styles.input,styles.disable)}>
                            {phone_number}
                         </div>   
                    </div>
                </div>
                <div className={styles.inputBundle}>
                    <p className={styles.inputTitle}>E-mail</p>
                    <div className={styles.inputWrapper}>
                    <input placeholder="Enter Your Email" name='email' onChange={handleChange} value={email} type="email" className={styles.input}/>
                        <img onClick={clearInput} name='email' className={email.length>0 ?cx(styles.crossSign,styles.display): styles.crossSign} src={crossIcon} alt="crossIcon" />
                    </div>
                </div>
                <div className={styles.inputBundle}>
                    <p className={styles.inputTitle}>Date of birth</p>
                    <div className={styles.inputWrapper}>
                    <input placeholder="Enter Your DateOfBirth" name='dob' onChange={handleChange} value={dob} type="date" className={styles.input}/>
                    </div>
                </div>
                <div className={styles.inputBundle}>
                    <p className={styles.inputTitle}>Gender</p>
                    <div className={styles.chipsContainer}>
                        <div onClick={handleChipChange} name="gender" value="Male" className={gender==="Male"? cx(styles.chip,styles.active) : styles.chip}>
                            Male
                        </div>
                        <div onClick={handleChipChange} name="gender" value="Female" className={gender==="Female"? cx(styles.chip,styles.active) : styles.chip}>
                            Female
                        </div>
                        <div onClick={handleChipChange} name="gender" value="Other" className={gender==="Other"? cx(styles.chip,styles.active) : styles.chip}>
                            Others
                        </div>
                    </div>
                </div>
                <div className={styles.spacer}>

                </div>
                <div className={styles.mobileButtonContainer}>
                    <button onClick={SaveChanges} className={styles.payButton}>
                        SAVE CHANGES
                    </button>
                </div>
            </div>
            {
                showBottomSheet && 
                <div onClick={()=>{ toggleBottomSheet()}} className={styles.overlay}>
                    <div onClick={(e)=>{ e.stopPropagation() }} className={styles.popUp}>
                        <p className={styles.overlaytext1}>Choose a picture</p>
                        <div className={styles.overlayBar}>
                        </div>
                        <div>

                        <p className={styles.overlaytext2}>SELECT FROM GALLERY</p>
                        
                        </div>
                        <p className={styles.overlaytext2}>TAKE A PICTURE</p>
                        <p className={cx(styles.overlaytext2,styles.red)}>REMOVE PROFILE PICTURE</p>
                    </div>
                </div>
            }
        </div>
    )
}