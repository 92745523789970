import styles from "./MobileBottomNavBar.module.css"
import React,{ useState ,useEffect }  from "react";
import discover from "../../asset/icons/discover.png"
import discoverFill from "../../asset/icons/discoverFill.png"
import fleekIcon from "../../asset/icons/FleekIcon.png"
import fleekInactive from "../../asset/icons/FleekInactive.png"
import contactFill from "../../asset/icons/HumanFill.png"
import contact from "../../asset/icons/person.png"
import cx from 'classnames';
import { Link } from "react-router-dom";
import  Api from "../../utils/Api/Api";



export const MobileBottomNavBar = ({type, isLoggedIn})=>{
    const [userDetails,setUserDetails] = useState({
        avatar_url:''
    })
    useEffect(()=>{
        const fetchUserDetails = async ()=>{
            try {
                let { data: {name ,email , avatar_url , dob , gender, phone_number} = {} } = await Api.GetUserDetails();
     
                setUserDetails({
                    avatar_url: avatar_url || ''
                })
            }
            catch(err) {
                // setError(true);
            }
            // setLoading(false)
        }
        if(isLoggedIn)
        fetchUserDetails();
    },[])
    return(
        <div className={styles.card}>
            <Link style={{ textDecoration: 'none' }} to='/marketplace' className={styles.cardDiv}>
                <img className={styles.icon} src={ type==="marketplace" || type==="" ? discoverFill : discover} alt="icon"/>
                <p className={ type==="marketplace" || type===""? cx(styles.text,styles.active) :styles.text}>Discover</p>
            </Link>
            <Link style={{ textDecoration: 'none' }} to='/fleektab' className={styles.cardDiv}>
                <img className={styles.icon} src={ type==="fleektab" ? fleekIcon : fleekInactive} alt="icon"/>
                <p className={ type ==="fleektab" ?cx(styles.text,styles.active) : styles.text}>Fleek</p>
            </Link>
            <Link style={{ textDecoration: 'none' }} to='/account' className={styles.cardDiv}>
                {
                   userDetails.avatar_url ?
                   <img className={ cx(styles.icon,styles.round)} src={ userDetails.avatar_url} alt="icon"/>:
                   <img className={styles.icon} src={ type==="account" ? contactFill: contact} alt="icon"/>
                }
                <p className={ type==="account"? cx(styles.text,styles.active) :styles.text}>Account</p>
            </Link>
        </div>
    )
}