import styles from "./BrandImgInfoBanner.module.css";
import Helper from '../../utils/Helper';
import chevronLeft from "../../asset/icons/chevronLeft.png";
import { ReadMoreComp } from "../../components/ReadMoreComp/ReadMoreComp";
import { useNavigate } from "react-router-dom";
import { CoinNWallet } from "../../components";


export const BrandImgInfoBanner = ({brandData , isLoggedIn, type})=>{
    const navigate = useNavigate()
    const isMobile = Helper.isMobile()

    return(
        <div className={styles.brandInfoContainer}>
            <div className={styles.brandImageContainer}>
                <div className={styles.bannerNav}>
                    <img onClick={()=>{
                        navigate('/marketplace')
                    }} className={styles.chevronLeft} alt="chevronLeft" src={chevronLeft} />
                    {type !== 'bundle' && isMobile && isLoggedIn && <CoinNWallet isLoggedIn={isLoggedIn} />}
                    {/* <img onClick={()=>{
                        navigate('/cart')
                    }} className={styles.cartIcon} alt="cartIcon" src={cartIcon} /> */}
                </div>
                <img src={brandData.banner_img} alt="brandBanner"/>
                {
                    type === 'bundle' ?
                    <div className={styles.bannerImg}>
                    {
                        brandData.image.map((i, index) => {
                            return <img style={{ zIndex: brandData.image.length - index  }} key={index} className={styles.brandImgCard} src={i} alt="FleekCoin"/>
                        })
                    }
                </div>    
                    : ''
                }
                <div className={ styles.infoContainer}>
                    <div className={styles.LogoNameDetail}>
                        <div className={styles.LogoImageContainer}>
                            <img src={brandData.logo} alt="brandLogo"/>
                        </div>
                        <div className={styles.nameDetail}>
                            <p className={styles.brandName}>{brandData.name}</p>
                            {brandData.subscriptionCount ?<p className={styles.subscription}>{brandData.subscriptionCount} Subscriptions</p>: ''}
                        </div>
                    </div>
                    <div className={styles.brandinfoText}>
                        <ReadMoreComp cut={120} text={brandData.desc}/>
                    </div>
                </div>
            </div>
        </div>
    )
}