import styles from "./CouponCongrats.module.css";
import percent from "../../asset/icons/percentRectangle.png"
import React ,{useState, useEffect} from "react";

export const CouponCongrats = ({toggleCoupanCongrats, appliedCoupon}) => {
    const [second,setSecond] = useState(0);
    useEffect(()=>{
        const timeout = setTimeout(()=>{
            if(second===0){
                toggleCoupanCongrats()
            }
            else{
                setSecond((pre_state)=>(pre_state-1))
            }
        },1000)
        return (()=>{
            clearTimeout(timeout)
        })
    },[second])
    return (
        <div className={styles.overlay} onClick={toggleCoupanCongrats}>
            <div className={styles.popUp1} onClick={(e)=>{
                e.stopPropagation()
            }}>
                <img className={styles.percent} src={percent} alt="percent" />
                <p className={styles.popUp1Text1}>{appliedCoupon.coupon_code}</p>
                <p className={styles.popUp1Text2}>Yay! You saved ₹{appliedCoupon.amountSaved}</p>
            </div>
        </div>
    )
}
