const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const Helper = {
    dateFormat(d) {
        let date = new Date(d);
        if( date == 'Invalid Date') {
            return '';
        }
        return  `${date.getDate()} ${month[date.getMonth()]}, ${date.getFullYear()}` 
    },
    dateCrossed(d){
        let date = new Date(d);
        let today = new Date();
        if(!d){
            return false;
        }
        if(Date.parse(date)>=Date.parse(today)){
            return false;
        }
        else{
            return true;
        }
    },
    isMobile(){
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
}


export default Helper;