import style from "./BrandAgeConBottomSheet.module.css"
import ThanksLogo from "../../asset/icons/thanksLogo.png"
import { useNavigate } from "react-router-dom";
import  Api from "../../utils/Api/Api";
import cx from 'classnames';

export const BrandAgeConBottomSheet = ({toggleModal, modalData})=>{

    const navigate = useNavigate()


    const RedirectHandling = async (button, text) =>{

        if(text === 'View my subscription' || text === "NO I'M NOT") {
            navigate(button.action);
        }
        else if(text === 'OKAY, GOT IT') {
            toggleModal()
        }
        else if(text === 'YES, I AM ABOVE 18') {

            const payload = {
                vendor:button.action
            }
            await Api.AgeRestrictionUpdate(payload);
            toggleModal();
        }
    }
    return(
        <div className={style.overlay}>
           <div onClick={(e)=>{ e.stopPropagation() }} className={style.popUp}>
                <img onClick={() => {
                    if(modalData.type != 1) {
                        toggleModal()
                    }
                    }} src={modalData.image} alt="18-plus-sign" className={modalData.type===1?style.ageSign:style.Rupyee}></img>
                <div className={style.headText}>
                    {modalData.title}
                </div>
                <div className={style.disclamerText}>
                    {modalData.text}
                </div>
                {
                    modalData.type===1 && 
                    <div className={style.separator}>
                        <div className={style.bar}></div>
                        <img className={style.thanksImg} src={ThanksLogo} alt="Thanks-Img"/>
                        <div className={style.bar}></div>
                    </div>
                }
                <div className={!modalData.button2 || !modalData.button1 ? cx(style.buttonCollection, style.buttonJustify): style.buttonCollection}>
                    {modalData.button2 && <button onClick={() => RedirectHandling(modalData.button2, modalData.button2.text)} className={modalData.button2.cta ?cx(style.cutButton,style.active): style.cutButton}>{modalData.button2.text}</button>}
                    {modalData.button1 && <button onClick={() => RedirectHandling(modalData.button1, modalData.button1.text)} className={style.acceptButton}>{modalData.button1.text}</button>}
                </div>
                {/* <div className={style.bar2}>
                </div> */}
           </div>
        </div>
    )
}