import styles from "./UserPersonalDetailsCard.module.css"
import React,{ useState } from "react";
import cx from 'classnames';

import crossIcon from "../../asset/icons/crossCircle.png"

export const UserPersonalDetailsCard = ({userDetail,handleChange,eraseInput})=>{
    return (
        <div className={styles.personalDetailCard}>
            <p className={styles.title}>Personal details</p>
            <div className={styles.inputWrapper}>
                    <input className={styles.input}
                    onChange={handleChange}
                    placeholder="Enter Email"
                    name="email"
                    type="email"
                    value={userDetail.email || ''}
                    />
                    <img 
                        onClick={eraseInput}
                        name="email"
                        className={userDetail.email?.length>0 ?cx(styles.crossSign,styles.display): styles.crossSign}
                         src={crossIcon} alt="crossIcon" />
            </div>
            <div className={styles.inputWrapper}>
                    <p className={styles.countryCode}>+91</p>
                    <input className={cx(styles.input,styles.marginLeft)}
                    disabled
                    // onChange={handleChange}
                    name="phone"
                    placeholder="Enter Phone Number"
                    type="number"
                    value={userDetail.phone}
                    />
                    {/* <img 
                    onClick={eraseInput}
                    name="phone"
                    className={userDetail.phone.length>0 ?cx(styles.crossSign,styles.display): styles.crossSign}
                     src={crossIcon} alt="crossIcon" /> */}
            </div>
        </div>
    )
}