import styles from "./ManageSubPopUp.module.css";
import FleekTabImg from "../../asset/icons/FleekTabImage.png"
import ScannerImg from "../../asset/icons/scanner.png"
import AppStore from "../../asset/icons/appStore.png"
import GooglePlay from "../../asset/icons/googlePlay.png"
import CrossSign from "../../asset/icons/cross.png"
import React  from "react"


export const ManageSubPopUp = ({togglePopUp})=>{
    return(
        <div className={styles.overlay}>
            <div className={styles.popUp}>
                <div className={styles.content}>
                    <img src={FleekTabImg} alt="FleekTabImg" className={styles.fleekImg}/>
                    <p className={styles.text1}>Manage</p>
                    <p className={styles.text2}>
                        Auto-pay, Share and Cancel your<br/>
                        subscriptions only on Fleek!
                    </p>
                    <a className={styles.customButton} href="https://getfleek.onelink.me/MDpg/l4qs48w8" rel="noreferrer" target="_blank">
                        DOWNLOAD NOW
                    </a>
                    <div className={styles.DesktopTab}>
                        <img src={ScannerImg} alt="ScannerImg" className={styles.scannerImg}/>
                        <div className={styles.side}>
                            <p className={styles.head}>
                                Install the app to Manage<br/> your subscriptions
                            </p>
                            <p className={styles.head1}>
                                Scan to Install
                            </p>
                            <div className={styles.buttonContainer}>
                                {/* <a className={styles.buttonAnchor} href="https://getfleek.onelink.me/MDpg/l4qs48w8" target="_blank">
                                    <img className={styles.buttonImg} src={AppStore} alt="AppStore" />
                                </a>
                                <a className={styles.buttonAnchor} href="https://getfleek.onelink.me/MDpg/l4qs48w8" target="_blank">
                                    <img className={styles.buttonImg} src={GooglePlay} alt="GooglePlay" />
                                </a> */}
                                <a className={styles.downloadNow} href="https://getfleek.onelink.me/MDpg/l4qs48w8" rel="noreferrer" target="_blank">
                                    DOWNLOAD NOW
                                </a>
                            </div>
                        </div>
                    </div>
                    <img onClick={togglePopUp} className={styles.crossSign} src={CrossSign} alt="CrossSign"/>
                </div>
            </div>
        </div>    
    )
}