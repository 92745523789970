import styles from "./AccountSideButton.module.css"
import { Link } from "react-router-dom";
import cx from 'classnames';
import { SignOutConfirmationPopUp } from "../SignOutConfirmationPopUp/SignOutConfirmationPopUp";
import React,{ useState  }  from "react";
export const AccountSideButton = (props)=>{
    const {icon,title,subTitle,extraText, type,isActive ,toggleModal} = props;
    const [showModal,setShowModal] = useState(false);
    const handleClick = (t) => {
        if(t === 'Sign Out') {
            toggleModal()
            // localStorage.removeItem('accesstoken');
            // localStorage.removeItem('phoneNo');
        }
    }
    return(
        <Link style={{pointerEvents: type ? '' : 'none', textDecoration: 'none' }} to={type==="login"?"":`/${type}`}>
            <div className={isActive ? cx(styles.card,styles.active):styles.card }>
                {icon && <img src={icon} alt="icon" className={styles.icon}/>}
                <div className={styles.texts}>
                    {title && <p onClick={() => handleClick(title)} className={styles.text1}>{title}</p>}
                    {subTitle && <p className={styles.text2}>{subTitle}</p>}
                    {extraText && <p className={styles.text3}>{extraText}</p>}
                </div>
            </div>
            {/* {showModal && <SignOutConfirmationPopUp toggleModal={toggleModal}/>} */}
        </Link>
        
    )
}