import styles from "./SupportComp.module.css"
import React , {useState} from "react";
import chevronLeft from "../../asset/icons/chevronLeft.png";
import WhatsAppIcon from "../../asset/icons/WhatsAppIcon.png"
import GmailIcon from "../../asset/icons/Gmail.png"
import CallIcon from "../../asset/icons/Communication.png"
import { useNavigate } from "react-router-dom";
import cx from 'classnames';
import Helper from "../../utils/Helper";


export const SupportComp = ()=>{
    const isMobile = Helper.isMobile()
    const navigate = useNavigate()
    return(
        <div>
            <div className={styles.header}>
                <p className={styles.headText}>Support</p>
                <img onClick={() => navigate('/account')} className={styles.chevronLeft} src={chevronLeft} alt="chevronLeft"/>
            </div>
            <div className={styles.cardContainer}>
                <div className={styles.actionContainer}>
                    <img src={WhatsAppIcon} alt="WhatsAppIcon" className={styles.icon}/>
                    <div className={styles.textContainer}>
                        <p className={styles.text1}>Chat with us</p>
                        <p className={styles.text2}>Our DM’s are open for you</p>
                    </div>
                    <p onClick={() => window.open( isMobile ? 'whatsapp://send?phone=+919606015198' :'https://web.whatsapp.com/send?phone=919606015198', '_blank', 'noopener,noreferrer')} className={styles.actionText}>CHAT</p>
                </div>
                <div className={styles.actionContainer}>
                    <img src={CallIcon} alt="CallIcon" className={styles.icon}/>
                    <div className={styles.textContainer}>
                        <p className={styles.text1}>Call us</p>
                        <p className={styles.text2}>Hit us up for any enquiry</p>
                    </div>
                    { isMobile ?
                        <a style={{ textDecoration: 'none' }} href="tel:+91 8046807763" className={styles.actionText}>CALL</a> :
                        <p className={cx(styles.actionText,styles.phone)}>+91 8046807763</p>

                    }
                </div>
                <div className={styles.actionContainer}>
                    <img src={GmailIcon} alt="GmailIcon" className={styles.icon}/>
                    <div className={styles.textContainer}>
                        <p className={styles.text1}>Email Us</p>
                        <p className={styles.text2}>We reply to emails too!</p>
                    </div>
                    <p onClick={() => window.open('mailto:help@getfleek.app?') } className={styles.actionText}>EMAIL</p>
                </div>
            </div>
            <div className={styles.text}>
                Customer care available from 10 AM to 7 PM
            </div>
        </div>
    )
}