import React from "react";
import cx from "classnames";
import styles from "./GetOnPlayStoreBadge.module.css"; // replace with your actual stylesheet path

export const GetOnPlayStoreBadge = ({ errorText }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <a href="https://getfleek.onelink.me/MDpg/l4qs48w8">
        <img
          src="https://www.svgrepo.com/show/303139/google-play-badge-logo.svg"
          alt="Play Store Logo"
          style={{
            height: 300,
            width: 300,
          }}
        />
      </a>
    </div>
  );
};
