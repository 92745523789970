import styles from "./NumberEntryPopUp.module.css";
import React,{ useState ,useRef } from "react";
import cx from 'classnames';
import { LoginSignupCommon } from "../../commonComponents";
import chevronLeft from "../../asset/icons/chevronLeft.png"
import crossIcon from "../../asset/icons/crossCircle.png"
import { useNavigate } from "react-router-dom";
import GooglePlay from "../../asset/icons/googlePlay.png"
import { GetOnPlayStoreBadge } from "../GetOnPlayStoreBadge/GetOnPlayStoreBadge";


export const NumberEntryPopUp = ({toggle, GetOtp})=>{
    const navigate = useNavigate()
    const [number,setNumber] = useState("");
    const [errorText,setErrorText] = useState("");
    const input = useRef();
    const handleChange = (e)=>{
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if(e.target.value.length===0){
            setErrorText("")
            setNumber(e.target.value);
            return;
        }
        if(format.test(e.target.value) && e.target.value.length<=10){
            setErrorText("Phone number cannot contain special characters")
            setNumber(e.target.value)
            return;
        }
        if(e.target.value.length<10){
            if(errorText){
                setErrorText("")
            }
            setNumber(e.target.value)
            return;
        }
        if(e.target.value.length===10){
            setNumber(e.target.value);
            setErrorText("")
        }
        
    }
    const stopPropagation = (e)=>{
        e.stopPropagation();
    }

    const clearInput = ()=>{
        setNumber("");
        setErrorText("");
        input.current.focus();
    };

    const handleSubmit = (e)=>{
        e.preventDefault();
        if(number.length<10){
            setErrorText("Enter valid number")
            return;
        }
        GetOtp(number)
    }

    const handleToggle = ()=>{
        const { innerWidth: width } = window;
        if(width>=1280){
            return;
        }
        toggle();
    }
    return (
      <div onClick={handleToggle} className={styles.overlay}>
        <div onClick={stopPropagation} className={styles.popUp}>
          {/* <div className={styles.detail}>
                    <p className={styles.title1}>Enter your number</p>
                    <p className={styles.title2}>We will send OTP to this number</p>
                    <p className={styles.phoneTitle}>Phone number</p>
                </div> */}
          <img
            onClick={() => navigate(-1)}
            className={styles.chevron}
            src={chevronLeft}
            alt="chevronLeft"
          />
          <LoginSignupCommon
            isLogin={true}
            title={"Welcome to Fleek"}
            subtitile={"Enter your phone number to continue"}
          />
          <label className={styles.title}>Phone number</label>
          <form id="LoginForm" onSubmit={handleSubmit}>
            <div
              className={
                errorText
                  ? cx(styles.inputWrapper, styles.error)
                  : styles.inputWrapper
              }
            >
              <p className={styles.countryCode}>+91</p>
              <input
                onWheelCapture={(e) => {
                  e.target.blur();
                }}
                onKeyDown={(evt) =>
                  (evt.key === "e" ||
                    evt.key === "+" ||
                    evt.key === "-" ||
                    evt.key === ".") &&
                  evt.preventDefault()
                }
                ref={input}
                onChange={handleChange}
                value={number}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                className={styles.input}
              />
              <img
                onClick={clearInput}
                className={
                  number.length > 0
                    ? cx(styles.crossSign, styles.display)
                    : styles.crossSign
                }
                src={crossIcon}
                alt="crossIcon"
              />
            </div>
            {errorText.length > 0 && (
              <p className={styles.errorText}>{errorText}</p>
            )}
          </form>
          <button
            form="LoginForm"
            disabled={errorText}
            className={
              !errorText
                ? cx(styles.continueButton, styles.active)
                : styles.continueButton
            }
            // className={cx(styles.continueButton,styles.active)}
            onClick={handleSubmit}
            type="submit"
          >
            CONTINUE
          </button>
          <GetOnPlayStoreBadge/>
        </div>
      </div>
    );
}