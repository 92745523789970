import styles from "./BundleMViewCard.module.css"
import cx from 'classnames';
import { useNavigate } from "react-router-dom";
import FleekCoin from "../../asset/icons/fleek-coin-logo.png"
import ChevronForward from "../../asset/icons/chevron.forward.png"


export const BundleMViewCard = ({plan})=>{
    const navigate = useNavigate();
    
    return(
        <div onClick={() => navigate(`/bundle/${plan.bundle_web_path}`)}  className={cx(styles.card)}>
            <div className={styles.bundleTextContainer}>
                <div className={cx(styles.flexRow, styles.infoContainer)}>
                <div className={styles.flexRow}>
                    {
                        plan.image.map((i, index) => {
                            return <img key={index} className={styles.brandImgCard} src={i} alt="FleekCoin"/>
                        })
                    }
                    
                </div>
                <div className={styles.brandTag}>{plan.category}%  OFF |
                     <img src={FleekCoin} alt="FleekCoin"/>
                    </div>
                </div>
                
                
                <div className={cx(styles.flexRow, styles.infoContainer, styles.paddingT1)}>
                    <div className={styles.planName}> {plan.planName}  </div>
                    <img className={styles.chevronForward} src={ChevronForward} alt="FleekCoin"/>
                </div>
                
                <div className={cx(styles.flexRow, styles.marginT18)}>
                    <p className={styles.priceText}>Worth : &nbsp;</p>
                    <p className={styles.planPrice}> ₹{Math.round(plan.mrp_price)}</p>
                </div>
                <div className={styles.flexRow}>
                    <p className={styles.fleekText}>Fleek price : &nbsp;</p>
                    <div className={styles.fleekCoinValue}>
                        <p className={styles.fleekPrice} >₹{Math.round(plan.discounted_cost)} +</p> 
                        <img src={FleekCoin} alt="FleekCoin"/>
                        <p className={styles.fleekPrice}>₹{Math.round(plan.max_coin_usable )}</p>
                    </div>
                    {/* <p className={styles.fleekPrice}>₹ {plan.mrp_price}</p> */}
                </div>
            </div>
            <div className={styles.detailContainer}>
                {plan.description}
            </div>
        </div>
    )
}