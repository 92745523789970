import styles from "./D2CSubsBoxScrn.module.css"
import React  from "react"
import chevronDown from "../../asset/icons/chevronDown.png"



export const D2CSubscriptionBoxDetailScreen = ({toggleCard})=>{
    return(
        <div className={styles.card}>
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJ1EisAWkP1DlFr8ZzDWolz9u_z-UqrEmcXtfJNelQ&s" alt="productImg" className={styles.productImg}/>
            <div className={styles.info}>
                <div className={styles.title}>Kitchen Towel</div>
                <div className={styles.detail}>Eco Friendly Reusable Kitche Towel Roll 20 Sheets. Strong and durable vlsdvj</div>
                <div className={styles.price}>₹350</div>
            </div>
            <img onClick={toggleCard} className={styles.chevron} src={chevronDown} alt="Cheveron-Down"/>
        </div>
    )
}