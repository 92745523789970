import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import styles from "./SingleBrandPage.module.css";

import AgeSign from "../../asset/icons/18PlusSign.png";
import RupeeCycle from "../../asset/icons/RupyeeCycle.png";
import FleekTiltIcon from "../../asset/icons/fleekTiltIcon.png";
import { useParams } from "react-router-dom";
import { ReadMoreComp } from "../../components/ReadMoreComp/ReadMoreComp";
import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import {
  DigitalBrandPlan,
  DigitalBrandBenefit,
  BrandAgeConBottomSheet,
  DigitalBrandFaq,
  TermNConditions,
  D2CSubscriptionBoxCard,
  BrandUserDetailsBottomSheet,
} from "../../components";
import { BrandImgInfoBanner } from "../../commonComponents";
import Api from "../../utils/Api/Api";
import Helper from "../../utils/Helper";

export const SingleBrandPage = ({ isLoggedIn }) => {
  const { vendor_name } = useParams();
  const navigate = useNavigate();

  const [brandData, setBrandData] = useState({
    name: "",
    banner_img: "",
    logo: "",
    desc: "",
    plans: [],
    termNCond: [],
    faqList: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [extraDetailsReq, setExtraDetailsReq] = useState(false);
  const [extraDetailsData, setExtraDetailsData] = useState({});

  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const [selectedPlanIdx, setSelectedPlan] = useState(-1);
  const [isD2c, setIsD2c] = useState(false);

  const boxes = [1, 2, 3, 4, 5, 6];
  localStorage.removeItem("bundleId");

  const toggleModal = () => {
    setShowModal((pre_state) => !pre_state);
  };
  const AddToCart = async (details) => {
    try {
      if (!isLoggedIn) {
        localStorage.setItem("brandId", vendor_name);
        return navigate("/login");
      }

      if (activeSubscription) {
        setShowModal(true);
        return;
      }
      if (
        extra_details_required &&
        extra_details_required.length &&
        !(
          extra_details_required.length + 1 ===
          Object.keys(extraDetailsData).length
        )
      ) {
        setExtraDetailsReq(true);
        setExtraDetailsData((pre_state) => {
          return { ...pre_state, ...details };
        });
        return;
      }
      //remove all items from cart
      await Api.EmptyCart();
      // add plan to cart
      await Api.AddToCart(details);
      navigate("/checkout");
    } catch (err) {
      console.log("=========err===========", err);
    }
  };

  useEffect(() => {
    const fetchBrandData = async () => {
      try {
        let {
          data: { vendor_data, plans_data },
        } = await Api.BrandDetails({
          params: {
            vendor_name,
            show_out_of_stock: true,
            extra_details_for_web: true,
          },
        });
        setBrandData({
          name: vendor_data.service.service_name,
          banner_img: vendor_data.detail_page_banner_url,
          logo: vendor_data.service.logo_url,
          desc: vendor_data.description,
          plans: plans_data,
          termNCond: vendor_data.terms_and_conditions,
          activeSubscription: vendor_data.is_already_availed_in_subscribe_flow,
          extra_details_required: vendor_data.extra_details_required,
          metaDetails: {
            title: vendor_data?.seo_title || "",
            content: vendor_data?.seo_description || "",
          },
          discount_percentage: vendor_data.discount_percentage,
          faqList: vendor_data.faq,
          overView: vendor_data.overview,
          benefits: vendor_data.benefits,
        });
        if (vendor_data.show_age_restrictions_popup) {
          setShowModal(true);
          setModalData({
            title: "You need to be above 18 years of age",
            text: "Disclaimer: This subscription may contain adult language and content. It is not suitable for anyone under the age of 18 and may not be suitable for all adult viewers, view at your discretion.",
            button2: {
              text: "NO I'M NOT",
              action: "/marketplace",
            },
            button1: {
              text: "YES, I AM ABOVE 18",
              action: vendor_data.vendor_id,
            },
            type: 1,
            image: AgeSign,
          });
        } else if (vendor_data.is_already_availed_in_subscribe_flow) {
          // setShowModal(true);
          setModalData({
            title: `You already have a recurring subscription for ${
              vendor_data.service.service_name
            }. ${
              vendor_data.due_date
                ? `Your next purchase will be done on ${Helper.dateFormat(
                    vendor_data.due_date
                  )}`
                : ""
            }`,
            text: "",
            button2: {
              text: "OKAY, GOT IT",
              action: "",
              cta: true,
            },
            type: 2,
            image: RupeeCycle,
          });
        }
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    };
    fetchBrandData();
  }, [vendor_name]);

  if (hasError) {
    return <ErrorFallback />;
  }
  if (loading) {
    return <Loading />;
  }
  const {
    benefits,
    plans,
    termNCond,
    overView,
    faqList,
    activeSubscription,
    discount_percentage,
    extra_details_required,
    metaDetails = {
      title: "",
      content: "",
    },
  } = brandData;
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{metaDetails.title}</title>
        <meta content={metaDetails.content} name="description" />
        <meta content={metaDetails.title} property="twitter:title"></meta>
        <meta
          content={metaDetails.content}
          property="twitter:description"
        ></meta>
        <meta content="summary_large_image" name="twitter:card"></meta>
        <meta content={metaDetails.title} property="og:title"></meta>
        <meta content={metaDetails.content} property="og:description"></meta>
        <link rel="canonical" href={"/brand/" + vendor_name} />
        <meta property="og:type" content="website"></meta>
      </Helmet>
      {extraDetailsReq && (
        <BrandUserDetailsBottomSheet
          setExtraDetailsReq={setExtraDetailsReq}
          extra_details_required={extra_details_required}
          AddToCart={AddToCart}
          extraDetailsData={extraDetailsData}
          setExtraDetailsData={setExtraDetailsData}
        />
      )}
      {showModal && (
        <BrandAgeConBottomSheet
          toggleModal={toggleModal}
          modalData={modalData}
        />
      )}

      <div className={styles.displayDesktop}>{/* <NavBar/> */}</div>
      <div className={styles.brandPage}>
        <BrandImgInfoBanner isLoggedIn={isLoggedIn} brandData={brandData} />
        {!isD2c && (
          <div className={styles.content}>
            {discount_percentage ? (
              <div className={styles.fleekCoinContainer}>
                <div className={styles.fleekTextContainer}>
                  <div className={styles.fleekText}>
                    Upto{" "}
                    <p className={styles.fleekBoldText}>
                      {discount_percentage}%
                    </p>{" "}
                    OFF
                  </div>
                  <p className={styles.fleekOtherText}>using FleekCoins</p>
                </div>
                <img
                  className={styles.fleekTiltIcon}
                  src={FleekTiltIcon}
                  alt="FleekTiltIcon"
                />
              </div>
            ) : (
              ""
            )}
            <div className={styles.planSection}>
              <p className={styles.sectionTitle}>Plan</p>
              <div className={styles.planList}>
                {plans.map((ele, idx) => (
                  <DigitalBrandPlan
                    AddToCart={AddToCart}
                    plan={ele}
                    index={idx}
                    selectedPlanIdx={selectedPlanIdx}
                    setSelectedPlan={setSelectedPlan}
                    key={idx}
                  />
                ))}
              </div>
            </div>
            {termNCond.length > 0 && <TermNConditions terms={termNCond} />}
            {overView && overView.length && (
              <div className={styles.overViewSection}>
                <p className={styles.sectionTitle}>Overview</p>
                <div className={styles.overViewText}>
                  <ReadMoreComp cut={120} text={overView} />
                </div>
              </div>
            )}
            {selectedPlanIdx !== -1 && (
              <div className={styles.mobileButtonContainer}>
                <button
                  onClick={() =>
                    AddToCart({
                      subscription_plan: Number(
                        plans[selectedPlanIdx].subscription_plan_id
                      ),
                      quantity: plans[selectedPlanIdx].quantity,
                    })
                  }
                  className={styles.payButton}
                >
                  BUY NOW
                </button>
              </div>
            )}
            {benefits && (
              <div className={styles.benefitSection}>
                <DigitalBrandBenefit benefits={benefits} />
              </div>
            )}
            {faqList && faqList.length ? (
              <div className={styles.faqsSection}>
                <DigitalBrandFaq faqList={faqList} />
              </div>
            ) : (
              ""
            )}
            {selectedPlanIdx !== -1 && <div className={styles.mobSpacer}></div>}
            <div className={styles.spacer}></div>
          </div>
        )}
        {isD2c && (
          <div className={styles.content}>
            <div className={styles.boxContainer}>
              <p className={styles.d2cTitle}>Select a subscription box</p>
              <div className={styles.boxes}>
                {boxes.map((ele, idx) => (
                  <D2CSubscriptionBoxCard key={idx} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
