import styles from "./Footer.module.css"
import FleekIcon from "../../asset/icons/FleekIcon.png"
import AppStore from "../../asset/icons/appStore.png"
import GooglePlay from "../../asset/icons/googlePlay.png"
import Linkedin from "../../asset/icons/LinkedinLogo.png"
import Facebook from "../../asset/icons/FacebookLogo.png"
import Instagram from "../../asset/icons/InstagramLogo.png"
import Twitter from "../../asset/icons/TwitterLogo.png"
import { Link } from "react-router-dom";


export const Footer = ()=>{
    return(
        <div className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.content1}>
                        <Link className={styles.fleekTitleContainer} to={"/marketplace"}>
                            <img className={styles.fleekicon} src={FleekIcon} alt="FleekIcon" />
                            <p className={styles.fleektitle}>fleek</p>
                        </Link>
                        <p className={styles.downloadNow}>Download now</p>
                        <p className={styles.content1Text}>
                            Never pay full price for subscriptions again. 
                            And save more by tracking & managing subscriptions 
                            in the same place. Think Subscriptions, think Fleek!
                        </p>
                        <div className={styles.actionButtonContainer}>
                            <a className={styles.donloadNowButton} href="https://getfleek.onelink.me/MDpg/l4qs48w8" rel="noreferrer" target="_blank">
                                DOWNLOAD NOW
                            </a>
                        </div>
                    </div>
                    <div className={styles.content2}>
                        <p className={styles.contentHead}>Fleek</p>
                        <div className={styles.linkContainer}>
                            <a href="https://www.getfleek.app/about-us" className={styles.link} rel="noreferrer" target="_blank">About Us</a>
                            <a href="https://www.getfleek.app/careers" className={styles.link} rel="noreferrer" target="_blank">Careers</a>
                            <a href="https://www.getfleek.app/partner-with-us" className={styles.link} rel="noreferrer" target="_blank">Partner with us</a>
                            <a href="https://www.getfleek.app/faq/general" className={styles.link} rel="noreferrer" target="_blank">FAQ</a>
                            <a href="https://www.getfleek.app/blog" className={styles.link} rel="noreferrer" target="_blank">Blog</a>
                        </div>
                    </div>
                    <div className={styles.content3}>
                        <p className={styles.contentHead}>Help & Support</p>
                        <div className={styles.linkContainer}>
                            <a href="https://www.getfleek.app/privacy-policy" className={styles.link} rel="noreferrer" target="_blank">Privacy Policy</a>
                            <a href="https://www.getfleek.app/terms-and-conditions" className={styles.link} rel="noreferrer" target="_blank">Terms of Service</a>
                        </div>
                    </div>
                    <div className={styles.content4}>
                        <p className={styles.contentHead}>Follow us on</p>
                        <div className={styles.socialIconCOntainer}>
                            <a className={styles.socialIcon} href="https://mobile.twitter.com/getfleekdotapp" rel="noreferrer" target="_blank">
                                <img src={Twitter} className={styles.socialIconImg} alt="socialIconImg"/>
                            </a>
                            <a className={styles.socialIcon} href="https://www.instagram.com/getfleekapp/" rel="noreferrer" target="_blank">
                                <img src={Instagram} className={styles.socialIconImg} alt="socialIconImg"/>
                            </a>
                            <a className={styles.socialIcon} href="https://www.facebook.com/getfleekdotapp" rel="noreferrer" target="_blank">
                                <img src={Facebook} className={styles.socialIconImg} alt="socialIconImg"/>
                            </a>
                            <a className={styles.socialIcon} href="https://www.linkedin.com/company/getfleekapp" rel="noreferrer" target="_blank">
                                <img src={Linkedin} className={styles.socialIconImg} alt="socialIconImg"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.horizontalBar}>
                </div>
                <div className={styles.rightsText}>
                    ©2021-2023 FLEEK TECHNOLOGIES PRIVATE LIMITED. All rights reserved.
                </div>
            </div>
        </div>
    )
}