import styles from "./SearchComp.module.css";
import React from "react";
import searchIcon from "../../asset/icons/searchIcon.png"
import crossIcon from "../../asset/icons/crossCircle.png"
import Helper from "../../utils/Helper";
import CrossSign from "../../asset/icons/whiteCross.png"
import cx from 'classnames';
import {DebounceInput} from 'react-debounce-input';


export const SearchComp = ({ searchValue, setSearchValue, showMobileSearch, toggleMobSearch }) => {
    const handleChange = (e) => {
        setSearchValue(e.target.value);
    }
    const eraseInput = () => {
        setSearchValue("")
    }
    const isMobile = Helper.isMobile();
    return (
        <>
            {
                isMobile ?
                    showMobileSearch ?
                        <div className={styles.content}>
                            <div className={isMobile ? cx(styles.inputWrapper, styles.mobile) : styles.inputWrapper}>
                                <img className={styles.searchIcon} src={searchIcon} alt="searchIcon" />
                                <DebounceInput
                                    className={styles.input}
                                    minLength={1}
                                    placeholder="Search your favourite brands"
                                    type="TEXT"
                                    value={searchValue}
                                    debounceTimeout={300}
                                    onChange={handleChange} />
                                { !isMobile && <img
                                    onClick={eraseInput}
                                    name="search"
                                    className={searchValue.length > 0 ? cx(styles.crossSign, styles.display) : styles.crossSign}
                                    src={crossIcon} alt="crossIcon" />}
                            </div>
                            <img onClick={()=>{
                                eraseInput();
                                toggleMobSearch();
                            }} src={CrossSign} alt="CrossSign" className={styles.CrossSign} />
                        </div>
                        : <img onClick={toggleMobSearch} className={styles.searchIcon1} src={searchIcon} alt="searchIcon"/>
                    :
                    <div className={styles.content}>
                        <div className={styles.inputWrapper}>
                            <img className={styles.searchIcon} src={searchIcon} alt="searchIcon" />
                            <input className={styles.input}
                                onChange={handleChange}
                                placeholder="Search your favourite brands"
                                type="TEXT"
                                value={searchValue}
                            />
                            <img
                                onClick={eraseInput}
                                name="search"
                                className={searchValue.length > 0 ? cx(styles.crossSign, styles.display) : styles.crossSign}
                                src={crossIcon} alt="crossIcon" />
                        </div>
                        {isMobile && <img src={CrossSign} alt="CrossSign" className={styles.CrossSign} />}
                    </div>

            }
        </>
    )
}
