import styles from "./VerifyNumber.module.css";
import { useState, useRef, useEffect } from "react";
import smartlook from 'smartlook-client'

import chevronLeft from "../../asset/icons/chevronLeft.png"
import cx from 'classnames';
import Api from "../../utils/Api/Api";
import { LoginSignupCommon } from "../../commonComponents";
import { useNavigate } from "react-router-dom";

export const VerifyNumber = () => {
    const navigate = useNavigate()

    const [otpArray, setOtpArray] = useState(["", "", "", ""]);
    const [error, setError] = useState(false);
    const [isfilled,setIsFilled] = useState(false)
    const [timer, setTimer] = useState(30);
    const input1 = useRef();
    const input2 = useRef();
    const input3 = useRef();
    const input4 = useRef();
    const arrayOfRef = [input1, input2, input3, input4];

    const onKeyDown = (e) => {
        const idx = Number(e.target.getAttribute("idx"));
        const value = e.target.value;
        if (e.code === "Backspace" && value === "") {
            if (idx !== 0) {
                setOtpArray((pre_array) => {
                    return pre_array.map((ele, i) => (
                        i + 1 === idx ? "" : ele
                    ))
                })
                arrayOfRef[idx - 1].current.focus();
            }
            if(isfilled){
                setIsFilled(false)
            }
        }
    }

    const handleErrorClick = (e) => {
        if (error) {
            const idx = Number(e.target.getAttribute("idx"));
            setOtpArray((pre_array) => {
                return pre_array.map((ele, i) => (
                    i >= idx ? "" : ele
                ))
            })

        }
    }

    const handleChange = (e) => {
        const idx = Number(e.target.getAttribute("idx"));
        const value = e.target.value;
        if (error) {
            setError(false);
        }
        setOtpArray((pre_array) => {
            return pre_array.map((ele, i) => (
                i !== idx ? ele : value.slice(value.length - 1)
            ))
        })
        if (value === "") {
            if (idx !== 0) {
                arrayOfRef[idx - 1].current.focus();
            }
        }
        else {
            if (idx !== 3) {
                arrayOfRef[idx + 1].current.focus();
            }
        }
        if(idx===3){
            if(otpArray[0] && otpArray[1] && otpArray[2] && value){
                setIsFilled(true)
            }
            else{
                setIsFilled(false)
            }
        }
        else if(idx!==3){
            if(isfilled){
                setIsFilled(false)
            }
        }
    };

    const handleStyle = (idx) => {
        if (error) {
            return cx(styles.otpBox, styles.error)
        }
        else {
            if (otpArray[idx] !== "") {
                return cx(styles.otpBox, styles.filled)
            }
            else {
                return styles.otpBox;
            }
        }
    }

    const submitButtonClass = () => {

        if (otpArray[0] && otpArray[1] && otpArray[2] && otpArray[3]) {
            if (error) {
                return styles.otpButton
            }
            else {
                return cx(styles.otpButton, styles.active);
            }
        }
        else {
            return styles.otpButton
        }
    }

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (timer !== 0) {
                setTimer((pre_state) => (pre_state - 1))
            }
        }, 1000);
        return (() => {
            clearTimeout(timeout);
        })
    }, [timer])

    useEffect(() => {
        const checkIfFilled = () => {
            if (otpArray[0] && otpArray[1] && otpArray[2] && otpArray[3] && !error) {
                VerifyOtp()
            }
        }
        checkIfFilled()
    },[isfilled])

    useEffect(() => {
        arrayOfRef[0].current.focus();
    }, []);

    const phoneNo = localStorage.getItem('phoneNo');

    const VerifyOtp = async () => {
        try {
            const { data } = await Api.VerifyOtp({ phone: phoneNo, otp: Number(otpArray.join('')) });

            if (!(data)) {
                setError(true);
                return;
            }
            localStorage.setItem('accesstoken', data.access_token);
            if(process.env.REACT_APP_ENV != 'production') {
                smartlook.identify(phoneNo)
              }

            const branchId = localStorage.getItem('brandId');
            const bundleName = localStorage.getItem('bundleName');

            localStorage.removeItem('brandId');
            localStorage.removeItem('bundleName');


            if (branchId) {
                navigate(`/brand/${branchId}`);
            }
            else if (bundleName) {
                navigate(`/bundle/${bundleName}`);
            }
            else {
                navigate('/marketplace');
            }
        }
        catch (err) {
            console.log('-------err--------------', err);
            setError(true);
        }
    }

    const GetOtp = async () => {
        await Api.GetOtp({ phone: phoneNo });
        setTimer(30)
    }

    return (
        <div className={styles.content}>
            <div className={styles.elements}>
                <div className={styles.mobileContent}>
                    <img onClick={() => navigate(-1)} className={styles.chevron} src={chevronLeft} alt="chevronLeft" />
                    <p className={styles.title}>Verify your number </p>
                    <p className={styles.subTitle}>We have sent an OTP to +91 {phoneNo}</p>
                </div>
                <LoginSignupCommon title={"Verify your number"} subtitile={`We have sent an OTP to +91 ${phoneNo}`} />
                <div className={styles.otpContainer}>
                    {
                        otpArray.map((_, idx) => (
                            <input type={"number"} onKeyDown={onKeyDown} onClick={handleErrorClick} onChange={handleChange} className={handleStyle(idx)} key={idx} ref={arrayOfRef[idx]} idx={idx} value={otpArray[idx]} />
                        ))
                    }
                </div>
                {error && <p className={styles.incorrectText}>Incorrect OTP, Try again!</p>}
                {
                    timer === 0 ?
                        <p onClick={GetOtp} className={styles.subText}>
                            Didn’t receive? <span className={styles.resendText}>RESEND CODE</span>
                        </p>
                        :
                        <p className={styles.subText}>
                            Request a new OTP in 00:{timer < 10 ? "0" + timer : timer}
                        </p>

                }
                <button disabled={error} onClick={VerifyOtp} className={submitButtonClass()}>CONFIRM OTP</button>
            </div>

        </div>
    )
}

