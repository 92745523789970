import styles from "./Loading.module.css"
import { ColorRing } from 'react-loader-spinner'

export const Loading = ()=>{
    return(
        <div className={styles.loaderContainer}>
            <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#00C2B1', '#00C2B1', '#00C2B1', '#00C2B1', '#00C2B1']}
            />
        </div>
    )
}