import styles from "./CoupanPopUp.module.css";
import React, { useState, useEffect , useMemo} from "react"
import CrossSign from "../../asset/icons/whiteCross.png"
import crossIcon from "../../asset/icons/crossCircle.png"
import chevronLeft from "../../asset/icons/chevronLeft.png";
import  Api from "../../utils/Api/Api";
import { Loading } from "../../components/Loading/Loading";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useNavigate } from "react-router-dom";

import cx from 'classnames';


export const CoupanPopUp = ({ toggleModal, cartData , toggleCoupanCongrats, appliedCoupon }) => {
    
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState(appliedCoupon && appliedCoupon.coupon_code? appliedCoupon.coupon_code: '' );
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(true);
    const [couponData,setCouponData] = useState([]);

    useEffect(()=>{
        const fetchCouponData = async ()=>{
            try {
                const payload = {
                    'subscription_plan_id' : cartData?.subscription_plan?.subscription_plan_id,
                    'bundle_id' : cartData?.subscription_plan?.bundle_id,
                    'type': cartData?.subscription_plan?.bundle_id ? 'bundle' : 'buy_once',
                }
                let { data } = await Api.GetAvailableCoupon(payload);
                setCouponData(data || []);
            }
            catch(err) {
                setError(true);
            }
            setLoading(false)
        }
        fetchCouponData();
    },[])

    const handleChange = (e) => {
        setSearchValue(e.target.value)
    }
    const eraseInput = async () => {
        setSearchValue('')
    }
    const handleApply = async (e, coupon_code, flow)=>{

        if(coupon_code !== undefined && !flow){
            setSearchValue(coupon_code);
        }

        coupon_code = coupon_code || searchValue;
        const payload = {
            'subscription_plan_id' : cartData?.subscription_plan?.subscription_plan_id,
            'bundle_id' : cartData?.subscription_plan?.bundle_id,
            'type': cartData?.subscription_plan?.bundle_id ? 'bundle' : 'buy_once',
            'coupon_code' : coupon_code
        }
        let { data } = await Api.ValidateCoupon(payload);
        if(data && data.error) {
            setError(data.error);
            return;
        }

        if(data.is_valid) {
            // show popup
            const params = {
                coupon_code: data.id,
                bundle: cartData?.subscription_plan?.bundle_id,
            }
            const {data : discountDetails} = await Api[cartData?.subscription_plan?.bundle_id ? 'BundleCheckout' :'GetOrderAmount'](params);
            toggleModal();
            toggleCoupanCongrats({coupon_code, id:data.id, amountSaved: data.final_discount_amount, discountDetails })
            
        }
        setError('')
        
    }
    return (
        <div className={styles.overlay}>
           <div className={styles.popUp}>
                <div className={styles.header}>
                    <p className={styles.applyTextHead}>Apply Coupon</p>
                    <img onClick={toggleModal} src={CrossSign} alt="CrossSign" className={styles.crossText} />
                    <img onClick={() =>  navigate('/checkout')} className={styles.chevronLeft} src={chevronLeft} alt="chevronLeft" />
                </div>
                <div className={styles.header2}>
                    <div className={styles.inputParent}>
                        <div className={ error.length>0 ? cx(styles.inputWrapper,styles.error) : styles.inputWrapper }>
                            <input className={styles.input}
                                onChange={handleChange}
                                placeholder="Enter Coupon code"
                                type="TEXT"
                                value={searchValue}
                            />
                            <img
                                onClick={eraseInput}
                                name="email"
                                className={searchValue.length > 0 ? cx(styles.crossSign, styles.display) : styles.crossSign}
                                src={crossIcon} alt="crossIcon" />
                        </div>
                        <button onClick={handleApply} className={styles.mobileGlobalApply}>APPLY</button>
                    </div>
                    {
                        error.length>0 && <p className={styles.error}>{error}</p>
                    }
                </div>
                <div className={styles.cardContainer}>
                    {
                        couponData.map((ele, idx) => (
                            <div key={idx} className={ele.is_valid ? styles.card : cx(styles.card, styles.inactive)}>
                                <div className={styles.row1}>
                                <CopyToClipboard text={ele.is_valid ? ele.coupon_code : ''} >
                                <p className={ele.is_valid ? styles.coupanCode : cx( styles.coupanCode,  styles.inactive)}>{ele.coupon_code}</p>
                                </CopyToClipboard>
                                    
                                    <p onClick={(e) =>ele.is_valid ? handleApply(e,ele.coupon_code, 1) : ''} className={ele.is_valid ? styles.applyText: cx(styles.applyText,  styles.inactive)}>APPLY</p>
                                </div>
                                <p className={ele.is_valid ? styles.extraText : cx( styles.extraText,  styles.inactive)}>{ele.error_msg}</p>
                            </div>
                        ))
                    }
                </div>
                {/* <button onClick={handleApply} className={styles.applyGlobal}>
                    APPLY
                </button> */}
            </div>
        </div>
    )
}