import styles from "./NavBar.module.css";
import FleekIcon from "../../asset/icons/FleekIcon.png"
import SearchIcon from "../../asset/icons/searchIcon.png"
import CartIcon from "../../asset/icons/cart.png";
import HumanIcon from "../../asset/icons/Human.png"
import React,{ useState ,useEffect }  from "react";
import { ManageSubPopUp } from "../../components";
import { Link } from "react-router-dom";
import cx from 'classnames';
import { CoinNWallet } from "../../components";
import  Api from "../../utils/Api/Api";


export const NavBar = ({hideNavOnMobile , fixedNavBar = true, image, isLoggedIn})=>{
    const [userDetails,setUserDetails] = useState({
        avatar_url:''
    })

    const [showSubPopUp,setShowSubPopUp] = useState(false)
    useEffect(()=>{
        const fetchUserDetails = async ()=>{
            try {
                let { data: {name ,email , avatar_url , dob , gender, phone_number} = {} } = await Api.GetUserDetails();
     
                setUserDetails({
                    avatar_url: avatar_url || ''
                })
            }
            catch(err) {
                // setError(true);
            }
            // setLoading(false)
        }
        if(isLoggedIn)
        fetchUserDetails();
    },[])

    if(!image) {
        image = userDetails.avatar_url
    }
    const NavBarStyle = ()=>{
        if(hideNavOnMobile){
            return cx(styles.NavBar , styles.hideOnMobile)
        }
        if(fixedNavBar){
            return cx(styles.NavBar , styles.fixedNavBar)
        }
        return styles.NavBar
    }
    const togglePopUp = ()=>{
        setShowSubPopUp((pre_state)=>(!pre_state))
    }
    return(
        <>
            <div className={styles.navContent}>
                <div className={NavBarStyle()}>
                    <Link className={styles.fleekTitleContainer} to={"/marketplace"}>
                        <img className={styles.fleekicon} src={FleekIcon} alt="FleekIcon" />
                        <p className={styles.fleektitle}>fleek</p>
                    </Link>
                    {/* <p className={styles.fleektitle}>Fleek</p> */}
                    <div className={styles.rightContent}>
                        {isLoggedIn && <CoinNWallet isLoggedIn={isLoggedIn} />}
                        <div onClick={togglePopUp} className={styles.hideInMob}>
                            <div className={styles.manageSubContainer}>
                                <img className={styles.fleekicon1} src={FleekIcon} alt="FleekIcon" />
                                <p className={styles.subText}>Manage subscriptions</p>
                            </div>
                        </div>
                        <Link style={{textDecoration: 'none'}} to={isLoggedIn ? '/account' : '/login'} className={styles.hideInMob}>
                            {
                                image?
                                <img className={styles.avatar} src={image} alt="Avatar"/>
                                :
                                <div className={styles.iconContainer}>
                                    <img className={styles.icon} src={HumanIcon} alt="HumanIcon" />
                                </div>
                            }
                            
                        </Link>
                        {/* <div className={styles.iconContainer}>
                            <img className={styles.icon} src={SearchIcon} alt="SearchIcon" />
                        </div>
                        <div className={styles.iconContainer}>
                            <img className={styles.icon} src={CartIcon} alt="CartIcon" />
                        </div> */}
                    </div>
                </div>
            </div>
            {
                fixedNavBar && !hideNavOnMobile && <div className={styles.spacer}>

                </div>
            }
            {
                showSubPopUp && <ManageSubPopUp togglePopUp={togglePopUp}/>
            }
        </>
        
    )
}