import styles from "./Cart.module.css";
import React from "react";
import chevronLeft from "../../asset/icons/chevronLeft.png"
import { ProductPurchaseCard , FreeProductPurchaseCard } from "../../components";

export const Cart = ()=>{
    return(
        <div className={styles.CartPage}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <img src={chevronLeft} alt="Chevron Left" className={styles.chevron}/>
                    <div className={styles.CartTitle}>
                        <span>Cart</span>
                        <span className={styles.itemCount}>2 Items</span>
                    </div>
                    <div className={styles.RemoveAll}>Remove all</div>
                </div>
                <div className={styles.cardContainer}>
                    <ProductPurchaseCard/>
                    <ProductPurchaseCard isDigital={true} />
                    <FreeProductPurchaseCard/>
                    <div className={styles.desktopPayButton}>
                        PROCEED TO PAY
                    </div>
                </div>
            </div>
        </div>
    )
}