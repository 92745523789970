import styles from "./TermNConditions.module.css"
import React , {useState} from "react"
import { ReadMoreComp } from "../ReadMoreComp/ReadMoreComp"
import exclamationIcon from "../../asset/icons/redExclamationIcon.png"


const allTermsShow = (terms,toggleReadMore)=>{
    return(
        <ul>
            {terms.map((ele,idx)=>(
                idx===terms.length-1
                    ?
                    <li key={idx} className={styles.text}>
                        {ele}
                        <span onClick={toggleReadMore} className={styles.read_or_hide}>
                            {"  ..SHOW LESS"}
                        </span>
                    </li>  
                    :
                    <li key={idx} className={styles.text}>
                        {ele}
                    </li> 
            ))}
        </ul>
    )
}

export const TermNConditions = ({terms})=>{
    terms = terms || [""];
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return(
        <div className={styles.termsSection}>
            <p className={styles.sectionTitle}>Terms & Conditions</p>
            <div className={styles.termContainer}>
                {isReadMore && <img src={exclamationIcon} alt="ExclamationIcom"/>}
                    <div className={styles.termText}>
                       {isReadMore?
                            <div className={styles.text}>
                                {terms[0]}
                                <span onClick={toggleReadMore} className={styles.read_or_hide}>
                                     ...SHOW MORE
                                </span>
                            </div>
                            :
                                allTermsShow(terms,toggleReadMore)
                       }
                    </div>
            </div>
        </div>
    )    
}