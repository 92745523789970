import React, { useState, useEffect } from "react";

import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import styles from "./BundleScreen.module.css"
import { useNavigate } from "react-router-dom";
import chevronLeft from "../../asset/icons/chevronLeft.png";

import { BundleMarketCard } from "../../components"
import Api from "../../utils/Api/Api";

import Helper from "../../utils/Helper";
import { BundleMViewCard } from "../../components/BundleMViewCard/BundleMViewCard";



export const BundleScreen = ({isLoggedIn}) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [hasError, setError] = useState(false);
    const [bundleData, setBundleData] = useState([]);
    const [bannerImg, setBannerImg] = useState([]);
    const isMobile = Helper.isMobile();


    const fetchBundleData = async () => {
        try {
            const promiseArr = [ Api.BundleList()]
            const [ bundle ] = await Promise.all(promiseArr);

            if(bundle?.data ) {
                setBannerImg(bundle.data.banner)
                setBundleData(bundle.data.bundles_list);
            }
        }
        catch (err) {
            console.log('=======err==========', err)
            setError(true);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchBundleData(20);
    }, [])


    const bundleView = () => {

        return bundleData.map((b) => {
            if(isMobile) {
                return <BundleMViewCard key={b.id} plan={{
                    planName: b.name,
                    mrp_price: b.mrp_price,
                    category: b.new_discount_percentage,
                    max_coin_usable: b.max_coin_usable,
                    discounted_cost: b.discounted_cost ,
                    image: b.plan_images_list,
                    id:b.id,
                    bundle_web_path: b.bundle_web_path
                }} screen={'bundle'} />
            }

            return <BundleMarketCard key={b.id} plan={{
                planName: b.name,
                mrp_price: b.mrp_price,
                category: b.new_discount_percentage,
                max_coin_usable: b.max_coin_usable,
                discounted_cost: b.discounted_cost ,
                image: b.plan_images_list,
                id:b.id,
                color: b.details?.colour_code,
                bundle_web_path: b.bundle_web_path
            }} screen={'bundle'} />
        })
    }

    if (hasError) {
        return <ErrorFallback />
    }
    if (loading) {
        return <Loading />
    }

    return (
        
        <div className={styles.bundle}>
            {
                isMobile ? 
                    <>
                        <div className={styles.bannerNav}>
                                <img onClick={()=>{
                                    navigate('/marketplace')
                                }} className={styles.chevronLeft} alt="chevronLeft" src={chevronLeft} />
                                
                        </div>
                        <img className={styles.bannerNavImg} src={bannerImg} alt="brandBanner"/>
                        <div className={styles.padding10}>
                        {bundleView()}
                        </div>
                    </> :
                    <>
                    <div className={styles.restContent}>
                        <p className={styles.sectionTitle}>Bundles</p>
                        <div className={ styles.cardContainer }>
                            {bundleView()}
                        </div>

                    </div>
                    <div className={styles.spacer}>
                        
                        </div>
                        
                    </>
            
            }
            
            
            </div>        

    )
}
