import styles from "./LoginSignupCommon.module.css"
import FleekIcon from "../../asset/icons/FleekIcon.png"
import cx from 'classnames';
import { useNavigate } from "react-router-dom";



export const LoginSignupCommon = ({isLogin,title,subtitile})=>{
    const navigate = useNavigate()
    const goback = ()=>{
        navigate('/marketplace')
    }
    return(
        <div className={isLogin ?cx(styles.container,styles.login): styles.container}>
            <img onClick={goback} className={styles.fleekicon} src={FleekIcon} alt="FleekIcon" />
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{subtitile}</p>
        </div>
    )
}