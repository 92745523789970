import styles from "./Checkout.module.css";
import chevronLeft from "../../asset/icons/chevronLeft.png";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import Api from "../../utils/Api/Api";
import Helper from "../../utils/Helper";
import FleekIcon from "../../asset/icons/Brand_logo_Sq.png";

import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import chevronRight from "../../asset/icons/chevronRight.png";
import { ToastContainer, toast } from "react-toastify";
import PercentCircle from "../../asset/icons/percentCircle.png";
import GreenPercentCircle from "../../asset/icons/GreenPercent.png";

import "react-toastify/dist/ReactToastify.css";
import { CoupanPopUp, CouponCongrats } from "../../components";

import {
  PaymentDetailsCard,
  UserPersonalDetailsCard,
  ProductPurchaseCard,
  FleekCoinDetailsCard,
  EarnFleekCoinsCard,
} from "../../components";

export const Checkout = ({ coupon = false }) => {
  const navigate = useNavigate();
  const isMobile = Helper.isMobile();
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const [showCoupanPopUp, setShowCoupanPopup] = useState(false);
  const [showCoupanCongratsPopUp, setShowCoupanCongrats] = useState(false);
  const [userDetail, setUserDetail] = useState({ email: "", phone: "" });
  const [cartData, setCartData] = useState([]);
  const [fleekCoins, setFleekCoins] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState({});
  // Fleek Coin State
  const [useFleekCoin, setUseFleekCoin] = useState(true);
  const toggleCoupanPopUp = () => {
    if (coupon === true) {
      navigate("/checkout");
      return;
    }
    setShowCoupanPopup((pre_state) => !pre_state);
  };
  const toggleUseFleekCoin = () => {
    setUseFleekCoin((pre_state) => !pre_state);
  };
  const bundleId = localStorage.getItem("bundleId");

  const toggleCoupanCongrats = (data) => {
    setShowCoupanCongrats((pre_state) => !pre_state);
    if (data && data.coupon_code) {
      setAppliedCoupon(data);
    }
  };

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const promise = [
          Api.GetFleekCoins(),
          Api.GetUserDetails(),
          Api.CreateJuspayId(),
        ];
        if (bundleId) {
          const params = {
            bundle: bundleId,
          };
          promise.push(
            Api.BundleCheckout(params),
            Api.BundleDetails({ params: { bundle_id: bundleId } })
          );
        } else {
          promise.push(Api.GetCartItems());
        }
        let [
          { data: fleekCoins },
          { data: userDetails },
          ,
          { data },
          bundleDetails,
        ] = await Promise.all(promise);

        if (bundleDetails?.data && bundleDetails.data.length) {
          data = [
            {
              subscription_plan: {
                service_name: "",
                plan_name: bundleDetails.data[0]?.bundle?.name || "",
                image_url: FleekIcon,
                bundle_id: bundleId,
              },
              discount_percentage_on_cost: data.discount_percentage_on_cost,
              actual_discounted_cost:
                data.final_payment_amount.coin_and_no_money,
              coin_used_percentage:
                Number(data.coin_used_percentage || 0) +
                data.discount_percentage_on_cost,
              cost: data.final_payment_amount.no_coin_and_no_money,
              mrp_price: bundleDetails.data[0]?.bundle?.mrp_price,
              coin_value_reserved: data.coin_value_reserved,
            },
          ];
        }
        setCartData(data);
        setFleekCoins(fleekCoins);
        setUserDetail({
          email: userDetails.email,
          phone: userDetails.phone_number,
        });
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    };
    fetchCartData();
  }, []);

  const PlaceOrder = async () => {
    if (!isAbleButton()) {
      return;
    }
    const payload = {
      screen_type: bundleId ? "bundle" : "buy_once",
      bundle: bundleId,
      use_coins: useFleekCoin,
      os: "web",
      email: userDetail.email,
      phone_number: userDetail.phone,
      discount_coupon_id: appliedCoupon?.id,
      buy_now: bundleId ? true : undefined,
    };
    let data = await Api.PlaceOrder(payload);

    if (!(data && data.data)) {
      toast(
        data?.response?.data?.error || "Somthing went wrong! Please try later."
      );
      return;
    } else {
      data = data.data;
    }

    const payloadPayment = {
      type: "MKP",
      is_fleek_money_used: false,
      currency: "INR",
      new_placed_ordered_id: data.id,
      os: "web",
      return_url: process.env.REACT_APP_PAYMENT_URL + "/order-confirmation",
    };
    const { data: paymentData } = await Api.GetPaymentLink(payloadPayment);

    if (paymentData && paymentData.is_purchase_done) {
      navigate(`/order?order_id=${paymentData.order_id}`);
    } else {
      window.location.href = paymentData.web;
    }
  };

  const RemoveCoupon = async () => {
    const params = {};
    if (bundleId) {
      params.bundle = bundleId;
    }
    const { data } = await Api[bundleId ? "BundleCheckout" : "GetOrderAmount"](
      params
    );
    setAppliedCoupon({ discountDetails: data });
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "phone" && value.length > 10) {
      return;
    }
    setUserDetail((pre_state) => {
      return { ...pre_state, [name]: value };
    });
  };
  const eraseInput = (e) => {
    const name = e.target.name;
    setUserDetail((pre_state) => {
      return { ...pre_state, [name]: "" };
    });
  };
  const isAbleButton = () => {
    if (userDetail.email?.length > 0 && userDetail.phone?.length === 10) {
      return true;
    }
    return false;
  };

  if (hasError) {
    return <ErrorFallback />;
  }
  if (loading) {
    return <Loading />;
  }

  let paymentDetails = cartData[0];
  if (appliedCoupon && appliedCoupon.discountDetails && !bundleId) {
    const {
      total_cost: mrp_price,
      total_cost_calculated,
      final_payment_amount,
      coupon_code_value,
    } = appliedCoupon.discountDetails;
    paymentDetails = {
      mrp_price,
      cost: total_cost_calculated,
      actual_discounted_cost: final_payment_amount.coin_and_no_money,
      coin_value_reserved:
        final_payment_amount.no_coin_and_no_money -
        final_payment_amount.coin_and_no_money,
      couponDiscount: coupon_code_value,
    };
  } else {
    paymentDetails.couponDiscount =
      appliedCoupon?.discountDetails?.coupon_code_value || 0;
    paymentDetails.cost =
      appliedCoupon?.discountDetails?.final_payment_amount
        ?.no_coin_and_no_money || paymentDetails.cost;
    paymentDetails.actual_discounted_cost =
      appliedCoupon?.discountDetails?.final_payment_amount?.coin_and_no_money ||
      paymentDetails.actual_discounted_cost;
  }
  return (
    <>
      <ToastContainer />

      <div className={styles.content}>
        <div className={styles.header}>
          <p className={cx(styles.headText, styles.mobile)}>Checkout</p>
          <img
            onClick={() => navigate(-1)}
            className={styles.chevronLeft}
            src={chevronLeft}
            alt="chevronLeft"
          />
        </div>
        <div className={styles.detailContainer}>
          <div className={styles.cardContainer}>
            <p className={cx(styles.headText, styles.desktop)}>Checkout</p>
            {cartData.map((d, i) => {
              return (
                <ProductPurchaseCard
                  useFleekCoin={useFleekCoin}
                  isDigital={false}
                  cartData={d}
                  key={i}
                />
              );
            })}
            {/* <FreeProductPurchaseCard/> */}
          </div>
          <div className={styles.otherDetail}>
            <UserPersonalDetailsCard
              userDetail={userDetail}
              handleChange={handleChange}
              eraseInput={eraseInput}
            />
            {cartData[0]?.coin_value_reserved &&
            Number(cartData[0].coin_value_reserved) ? (
              <FleekCoinDetailsCard
                fleekCoins={fleekCoins}
                useFleekCoin={useFleekCoin}
                toggleUseFleekCoin={toggleUseFleekCoin}
                cartData={cartData[0]}
              />
            ) : (
              <EarnFleekCoinsCard />
            )}
            {appliedCoupon.coupon_code ? (
              <div className={styles.appliedCoupanCard}>
                <img
                  src={GreenPercentCircle}
                  alt="GreenPercentCircle"
                  className={styles.circle}
                />
                <div className={styles.textContainer}>
                  <p className={styles.appliedCoupanText}>
                    {appliedCoupon.coupon_code} applied
                  </p>
                  <p className={styles.appliedSubText}>
                    Yay! You saved ₹{appliedCoupon.amountSaved}
                  </p>
                </div>
                <p onClick={RemoveCoupon} className={styles.removeText}>
                  REMOVE
                </p>
              </div>
            ) : (
              <div
                onClick={() =>
                  isMobile ? navigate("/coupon") : toggleCoupanPopUp()
                }
                className={styles.applyCoupanCard}
              >
                <img
                  src={PercentCircle}
                  alt="PercentCircle"
                  className={styles.circle}
                />
                <p className={styles.coupantext}>Apply Coupon</p>
                <p className={styles.newText}>NEW</p>
                <img
                  src={chevronRight}
                  alt="chevronRight"
                  className={styles.chevronRight}
                />
              </div>
            )}
            <PaymentDetailsCard
              useFleekCoin={useFleekCoin}
              cartData={paymentDetails}
            />
            <div className={styles.mobileButtonContainer}>
              <button
                onClick={PlaceOrder}
                className={
                  isAbleButton()
                    ? styles.payButton
                    : cx(styles.payButton, styles.inactive)
                }
              >
                PROCEED TO PAY
              </button>
            </div>
            {/* <button className={cx(styles.payButton,styles.desktop)}>
                            PROCEED TO PAY
                        </button> */}
          </div>
        </div>
      </div>
      {(coupon || showCoupanPopUp) && (
        <CoupanPopUp
          appliedCoupon={appliedCoupon}
          toggleModal={toggleCoupanPopUp}
          toggleCoupanCongrats={toggleCoupanCongrats}
          cartData={cartData[0]}
        />
      )}

      {showCoupanCongratsPopUp && (
        <CouponCongrats
          appliedCoupon={appliedCoupon}
          toggleCoupanCongrats={toggleCoupanCongrats}
        />
      )}
    </>
  );
};
