import styles from "./BrandUserDetailsBottomSheet.module.css";
import CrossSign from "../../asset/icons/crossCircle.png"
import cx from 'classnames';

export const BrandUserDetailsBottomSheet = ({extraDetailsData, extra_details_required = [], setExtraDetailsData, setExtraDetailsReq, AddToCart})=>{

    const handleChange = (e)=>{
        const name = e.target.name;
        const value = e.target.value;
        setExtraDetailsData((pre_state)=>{
            return {...pre_state, ...{details:{...extraDetailsData.details, [name]:value}}}
        });
    }
    const isAbleButton = ()=>{
        if(extraDetailsData.details?.child_dob && extraDetailsData.details?.child_name){
            return true
        }
        return false
    }

    return(
        <div className={styles.overlay} >
           <div className={styles.popUp}>
                <div className={styles.detailBanner}>
                    <div className={styles.subtitleCont}>
                        <p className={styles.subhead1}>Provide the details</p>
                        <p className={styles.subhead2}>To activate the subscription</p>
                    </div>
                    <img onClick={() => {setExtraDetailsReq(false)}} className={styles.cross} src={CrossSign} alt="cross-sign"/>
                </div>
                {extra_details_required.map((e) => {
                    return <div key={e.json_key} className={styles.inputContainer}>
                    <p className={styles.inputHeading}>{e.display_text}</p>
                    <input onChange={handleChange} name={e.json_key} type={e.type} className={styles.inputBox}/>
                </div>
                })}
                <div onClick={() => isAbleButton() ? AddToCart(extraDetailsData) : ''} className={ isAbleButton() ? styles.popUpCta : cx(styles.popUpCta, styles.inactive)}>
                    PROCEED
                </div>
           </div>
        </div>
    )
}