import styles from "./FCSavedCard.module.css"
import coinBundle from "../../asset/images/coinBundle.png";


export const FCSavedCard = ({usedFleekCoin = 0})=>{
    return(
        <div className={styles.card}>
            <div className={styles.detail}>
                <p className={styles.title1}>
                    You've saved total 
                </p>
                <p className={styles.title2}>
                    <span className={styles.bold}>₹ {Math.floor(usedFleekCoin)}</span> using FleekCoin!
                </p>
            </div>
            <img className={styles.coinBundle} src={coinBundle} alt="coinBundle" />
        </div>
    )
}