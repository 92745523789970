import React,{ useState ,useEffect } from "react";

import { D2CSubscriptionBoxDetailScreen } from "../D2CSubscriptionBoxDetailScreen/D2CSubscriptionBoxDetailScreen";
import { D2CSubscriptionBoxDetailScreenProductCard } from "../D2CSubscriptionBoxDetailScreenProductCard/D2CSubscriptionBoxDetailScreenProductCard";

export const D2CSubscriptionBoxDetail = ()=>{
    const [showClosed,setShowClosed] = useState(true);

    const toggleClosed = ()=>{
        setShowClosed((pre_state)=>(!pre_state))
    }

    return(
        <>
            {
                showClosed ? <D2CSubscriptionBoxDetailScreen toggleCard={toggleClosed}/> : <D2CSubscriptionBoxDetailScreenProductCard toggleCard={toggleClosed}/>
            }
        </>
    )
}