import "./CustomRadio.css"
import React from "react"

export const CustomRadio = ({name,checked,changeHandle})=>{
    return(
        <label className="custom-radio">
            <input
              type="radio"
              name={name}
              id="my_radio"
              className="radio__input"
              checked={checked}
              readOnly
            />
            <div className={`radio__radio ${checked ? "checked":"notChecked"}`}></div>
        </label>
    )
}