import styles from "./ProductPurchaseCard.module.css";
import plusIcon from "../../asset/icons/plusIcon.png";
import minusIcon from "../../asset/icons/minusIcon.png";
import FleekCoin from "../../asset/icons/fleek-coin-logo.png"
import FreeIcon from "../../asset/images/freeIcon.png";
import  Helper from "../../utils/Helper";
import cx from 'classnames';


export const ProductPurchaseCard = ({isDigital, cartData = {} ,useFleekCoin, orderPage})=>{

    const {
        product_image_url, quantity,
        subscription_plan: {
            service_name,
            plan_name,
            image_url
        }, mrp_price,
        actual_discounted_cost, coin_value_reserved,
        discount_percentage_on_cost,
        coin_used_percentage,
        cost,
        details

    } = cartData;

    let showMarkup = false;
    let displayFleekCoin = false;
    if(useFleekCoin) {
        showMarkup = coin_used_percentage > 0;
    }
    else{
        showMarkup = discount_percentage_on_cost > 0 ;
    }
    if(details?.extra_details_provided?.child_dob) {
        var dateOfBirth = Helper.dateFormat(details.extra_details_provided.child_dob);
    }

    if(coin_value_reserved && Number(coin_value_reserved) >0) {
        displayFleekCoin = useFleekCoin
    }
    else{
        displayFleekCoin = false;
    }    
    return(
        <div className={styles.card}>
            <div className={styles.mainInfoContainer}>
                <div className={styles.imageOptionContainer}>
                    <div className={styles.cartImageContainer}>
                        <img className={styles.cartImage} src={product_image_url || image_url} alt='cart'/>
                    </div>
                    {!orderPage && <div className={styles.priceContainer}>
                        {/* <button className={styles.priceButton}>
                            <img src={minusIcon} alt="minusIcon"/>
                        </button> */}
                        <p className={styles.qnty}>Quantity : {quantity || '1'}</p>
                        {/* <button className={styles.priceButton}>
                            <img src={plusIcon} alt="plusIcon"/>
                        </button> */}
                    </div>}
                </div>
                <div className={styles.infoConatiner}>
                    <p className={styles.brandName}>{service_name || 'BUNDLED SUBSCRIPTION'}</p>
                    <p className={styles.productName}>
                        {service_name? `${service_name},`: ''} {plan_name}
                    </p>
                        
                    <div className={styles.withFleekCoin}>
                            {!orderPage && showMarkup && <div className={cx(styles.flex_row,styles.priceFont)}>
                                <p className={styles.deskMainPrice}>₹ {Math.round(mrp_price)}</p>
                                <p className={styles.discount}>{displayFleekCoin? coin_used_percentage: discount_percentage_on_cost}% OFF</p>
                            </div>}
                            {
                                displayFleekCoin ? 
                                <div className={cx(styles.flex_row,styles.discountFont)}>
                                    <p className={styles.deskDiscountPrice}>
                                        ₹ {Math.round(actual_discounted_cost)} +
                                    </p>
                                    <img src={FleekCoin} alt="FleekCoin"/>
                                    <p className={styles.deskFleekPrice}>
                                        ₹ {Math.round(coin_value_reserved)}
                                    </p>
                                </div>:

                            !orderPage && <div className={styles.withoutFleekCoin}>
                                <span className={styles.paidPrice}>₹ {Math.round(cost)}</span>
                            </div>
                            }
                        </div>
                    
                    
                </div>
            </div>
            {details && details.extra_details_provided ? 
            <div>
                <hr className={styles.hrStyle} />
                <p className={styles.headExtraDetails}>Details</p>
                {details.extra_details_provided.child_name && <p className={styles.extraDetails}>Child name - {details.extra_details_provided.child_name}</p>}
                {dateOfBirth && <p className={styles.extraDetails}>Child's Date of Birth - {dateOfBirth}</p>}

            </div> : ''}
            {
                isDigital &&
                <div >
                    <div className={styles.seperator}>
                    </div>
                    <div className={styles.freeSection}>
                        <img className={styles.freeImage} src={FreeIcon} alt="FreeIcon"/>
                        <p className={styles.freeText}>Swiss Military Earbuds</p>
                    </div>
                </div>
            }
        </div>
    )
}