import styles from "./BundlePurchaseCard.module.css"
import cx from 'classnames';
// import { useNavigate } from "react-router-dom";

export const BundlePurchaseCard = ({length, index, plan})=>{
    // const navigate = useNavigate();
    // onClick={() => navigate(`/brand/${plan.subscription_plan_id}`)}

    return(
        <div className={(length === index + 1) ? cx(styles.card, styles.lastCardMargin) : styles.card}>
            <div className={styles.bundleImgContainer}>
                <img className={styles.bundleImage} src={plan.banner_url} alt="Bundle"/>
                <div style={{background:plan.label_color || '#A1804F'}} className={styles.brandTag}>{plan.category}</div>
            </div>
            <div className={styles.infoContainer}>
                <img src={plan.image_url} alt="logo" className={styles.logo}/>
                <div className={styles.nameDetail}>
                    <p className={styles.planName}>{plan.service_name}</p>
                    <p className={styles.planValidity}>{plan.duration} {plan.duration_type} plan</p>
                </div>
                <p className={styles.planPrice}>Worth ₹{Math.round(plan.mrp_price)}</p>
            </div>
            <div className={styles.detailContainer}>
                {plan.description}
            </div>
        </div>
    )
}