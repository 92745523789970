import styles from "./SubscriptionCouponDetailsCard.module.css"
import backImage from "../../asset/images/Subtract.png";
import LinkImg from "../../asset/icons/Link.png"
import  Helper from "../../utils/Helper";
import cx from 'classnames';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const SubscriptionCouponDetailsCard = ({orderDetail, is_deep_tech})=>{
    
    const dateTimeFormat = (time_of_expiry) => {
        return Helper.dateFormat(time_of_expiry);
    }

    const dateCrossed = (time_of_expiry) => {
        return Helper.dateCrossed(time_of_expiry)
    }
    
    const {coupon_code = {},  subscription_plan: {steps_to_access = ''} = {} } = orderDetail;
    let details = [];
    if(coupon_code && coupon_code.how_to_redeem){
        details = coupon_code.how_to_redeem;
        details = details.split('\n')
    }
    else if(steps_to_access){
        details = steps_to_access;
        details = details.split('\n')
    }

    if(is_deep_tech && !details.length) {
        return '';
    }
    return(
        <div className={styles.card}>
            {!is_deep_tech && <div className={styles.couponContainer}>
                <img src={backImage} className={styles.backGroundImage} alt="backGroundImage" />
                <div className={styles.upperText}>
                    <p className={styles.text1}>Your coupon activation link</p>
                    {coupon_code && coupon_code.coupon_code &&
                        <CopyToClipboard text={coupon_code.coupon_code} >
                        <p className={dateCrossed(coupon_code && coupon_code.time_of_expiry)? cx(styles.text2,styles.isActivated):styles.text2}>
                        {coupon_code.coupon_code}
                    </p>
                        </CopyToClipboard>
                    }
                    {coupon_code && coupon_code.coupon_link && <div className={styles.linkContainer}>
                        <img src={LinkImg} alt="LinkImg" className={styles.linkImg}/>
                        <p className={styles.link}>{coupon_code && coupon_code.coupon_link}</p>
                        <button className={dateCrossed(coupon_code && coupon_code.time_of_expiry)? cx(styles.activeButton,styles.isActivated):styles.activeButton}>ACTIVATE</button>
                        {/* <button className={cx(styles.activeButton,styles.isActivated)}>ACTIVATE</button> */}
                    </div>}
                    {!coupon_code &&    
                        <p className={dateCrossed(coupon_code && coupon_code.time_of_expiry)? cx(styles.text2,styles.isActivated):styles.text2}>XXXXXXXXXX</p>
                    }
                    {!coupon_code || !coupon_code.time_of_expiry ?
                     <p className={styles.text3}>We will Notify you once it's generated</p>:
                     <p className={styles.text3}>Expires on {dateTimeFormat(coupon_code && coupon_code.time_of_expiry)}</p>
                     }

                </div>
            </div>}
            {details && details.length ? <p className={styles.title}>{is_deep_tech ? 'How to access your account': 'How to use the Code'}</p> : ''}
            <ul className={styles.detail}>
                
                {
                    details.map((ele,idx)=>(
                        // <li className={styles.singleDetail} key={idx} >{ele}</li>
                        <li className={dateCrossed(coupon_code && coupon_code.time_of_expiry)? cx(styles.singleDetail,styles.isActivated):styles.singleDetail} key={idx} >{ele}</li>
                    ))
                }
            </ul>
        </div>
    )
}