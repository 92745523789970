import styles from "./D2CSubscriptionBoxCard.module.css";
import React  from "react"

export const D2CSubscriptionBoxCard = ()=>{
    return(
        <div className={styles.card}>
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJ1EisAWkP1DlFr8ZzDWolz9u_z-UqrEmcXtfJNelQ&s" alt="Brand Image"/>
            <div className={styles.allDetail}>
                <img src="https://c4.wallpaperflare.com/wallpaper/897/71/738/angry-birds-minimalism-wallpaper-preview.jpg" alt="Brand Logo" className={styles.brandLogo}/>
                <div className={styles.brandInfoParent}>
                    <div className={styles.brandName}>Hello</div>
                    <div className={styles.infoContainer}>
                        <div className={styles.productName}>Sustainable Home Starter Kit</div>
                    </div>
                    <div className={styles.priceContainer}>
                        <span className={styles.discountPrice}>₹500</span>
                        <span className={styles.mainPrice}>₹750</span>
                    </div>
                </div>
            </div>
            <div className={styles.discountContainer}>20% OFF</div>
        </div>
    )
}