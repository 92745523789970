import styles from "./PaymentSuccessModal.module.css";
import greenTick from "../../asset/icons/greenTick.png"
import FleekCoin from "../../asset/icons/fleek-coin-logo.png"
import React , {useState ,useEffect} from "react"
import CrossSign from "../../asset/icons/whiteCross.png"


export const PaymentSuccessModal = ({toggleModal, amount_saved_dict, payment})=>{
    const [second,setSecond] = useState(3);
    useEffect(()=>{
        const timeout = setTimeout(()=>{
            if(second===0){
                toggleModal()
            }
            else{
                setSecond((pre_state)=>(pre_state-1))
            }
        },1000)
        return (()=>{
            clearTimeout(timeout)
        })
    },[second])
    return(
        <div className={styles.overlay}>
            <div className={styles.popUp}>
                <img className={styles.greenTick} src={greenTick} alt="greenTick"/>
                <p className={styles.title}>Payment successful</p>
                <p className={styles.paymentDetail1}>We have received your payment of ₹ {Math.floor(payment.amount)}</p>
                <div className={styles.paymentDetail2}>
                    <p>
                        You saved ₹<span className={styles.boldText}>{Math.floor(amount_saved_dict.amount_saved)}</span> and <br/>
                        You earned ₹<span className={styles.boldText}>{Math.floor(amount_saved_dict.refund_amount)}</span> worth FleekCoin 
                        <img className={styles.fleekCoin} src={FleekCoin} alt={FleekCoin}/>
                    </p>
                </div>
                <img onClick={toggleModal} className={styles.CrossSign} src={CrossSign} alt="CrossSign" />
            </div>
            <div className={styles.mobileRedirectText}>
                You will be redirected in <span className={styles.boldWhite}>{second} </span>seconds
            </div>
        </div>    
    )
}