import styles from "./PaymentInstrumentDetailsCard.module.css"


export const PaymentInstrumentDetailsCard = ({payment = {}})=>{
    const {payment_method, method_logo_url, last_four_digits_card} = payment;
    return(
        <div className={styles.card}>
            <p className={styles.title}>Purchased using</p>
            <div className={styles.payemntInstrument}>
                {method_logo_url && <img src={method_logo_url} alt="cardIcon" className={styles.cardImage}/>}
                <div className={styles.cardDetail}>
                    <p className={styles.cardName}>
                        {payment_method}
                    </p>
                    {last_four_digits_card && <p className={styles.cardNumber}>{".... "+last_four_digits_card}</p>}
                </div>
            </div>
        </div>
    )
}