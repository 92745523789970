import styles from "./ProductPage.module.css";
import axios from 'axios';
import React,{ useState ,useEffect } from "react";
import { ErrorFallback } from "../../components/Error/Error";
import { Loading } from "../../components/Loading/Loading";
import { BrandImgInfoBanner } from "../../commonComponents";
import { D2CSubscriptionBoxDetail } from "../../components";

export const ProductPage = ()=>{
    const [productData,setProductData] = useState({
        name:"",
        banner_img:"",
        logo:"",
        desc:"",
        plans:[],
        termNCond:[],
    });
    const products = [1,2,3,4,5,6]
    const [loading,setLoading] = useState(true);
    const [hasError,setError] = useState(false);
    useEffect(()=>{
        const fetchBrandData = async ()=>{
            try {
                let { data: {vendor_data, plans_data } } = await axios.get("https://mocki.io/v1/6c440058-eb5e-4b7e-8cb4-a13cf1bd11ee");
                setProductData({
                    name:vendor_data.service.service_name,
                    banner_img: vendor_data.detail_page_banner_url,
                    logo:vendor_data.service.logo_url,
                    desc:vendor_data.description,
                    plans:plans_data,
                    termNCond:vendor_data.terms_and_conditions
                })
            }
            catch(err) {
                setError(true);
            }
            setLoading(false)
        }
        fetchBrandData();
    },[])
    if(hasError) {
        return <ErrorFallback />
    }
    if(loading) {
        return <Loading />
    }
    return(
        <div className={styles.productPage}>
            <BrandImgInfoBanner brandData={productData}/>
            <div className={styles.content}>
                <div className={styles.productBoxContainer}>
                    <p className={styles.title}>What it includes</p>
                    <div className={styles.boxesAndPrice}>
                        <div className={styles.boxes}>
                            {
                                products.map((ele,idx)=>(
                                    <D2CSubscriptionBoxDetail key={idx} />
                                ))
                            }
                        </div>
                        <div className={styles.priceInfo}>
                            <div className={styles.markedPriceContainer}>
                                <p>Worth</p>
                                <p className={styles.markedPrice}>₹ 750</p>
                            </div>
                            <div className={styles.actualPriceContainer}>
                                <p>Get at</p>
                                <p className={styles.actualPrice}>₹ 500</p>
                            </div>
                            <div className={styles.buyButton}>
                                BUY NOW
                            </div>                 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}