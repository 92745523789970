import styles from "./FleekCoinDetailsCard.module.css"
import checkBoxIcon from "../../asset/icons/checkboxTick.png";
import FleekCoin from "../../asset/icons/fleek-coin-logo.png"

import React ,{useState} from "react";


export const FleekCoinDetailsCard = ({fleekCoins, useFleekCoin, toggleUseFleekCoin, cartData = {}})=>{    

    if(!cartData.coin_value_reserved) {
        cartData.coin_value_reserved = 0;
    }
    return(
        <div className={styles.card}>
            <div className={styles.upperPortion}>
                <label className={styles.custom_checkbox}>
                        <input
                            onChange={toggleUseFleekCoin}
                            type="checkbox"
                            checked={useFleekCoin}
                            
                        />
                        {
                            useFleekCoin 
                            ?
                            <span className={styles.checkmark}>
                                <img src={checkBoxIcon} alt="checkBoxIcon" className={styles.checkBoxIcon} />
                            </span>
                            :
                            <span className={styles.checkmark}></span>
                        }
                </label>
                <div className={styles.coinDetail}>
                    <div className={styles.coinDetail1}>
                        <p>Pay <span className={styles.bold}>₹ {Math.floor(cartData.coin_value_reserved)}</span> using Fleekcoin</p>
                        <img src={FleekCoin} alt="FleekCoin" className={styles.FleekCoin} />
                    </div>
                    <div className={styles.coinDetail2}>
                        <p>Available balance :<span className={styles.coinColor}> ₹ {Math.floor(fleekCoins.coin_value_in_inr)}</span></p>
                    </div>
                </div>
            </div>
          {!useFleekCoin && <div className={styles.applyFleekSection}>
             Apply FleekCoin to save ₹ {Math.floor(cartData.coin_value_reserved)}
          </div>}
        </div>
    )
}

export const EarnFleekCoinsCard = () => {
    return (
      <div className={styles.card}>
        <div className={styles.upperPortion}>
          <div className={styles.coinDetail}>
            <div className={styles.coinDetail1}>
              <p>Earn Fleekcoins through the Fleek app and save more!</p>
              <img src={FleekCoin} alt="FleekCoin" className={styles.FleekCoin} />
            </div>
            <div className={styles.coinDetail2}>

                <a href="https://getfleek.onelink.me/xi8q/b6f5869b" target="_blank" rel="noopener noreferrer" className={styles.whiteLink}>
                  Download the app now!
                </a>
            </div>

          </div>
        </div>
      </div>
    );
  };